<form [formGroup]="roofYearForm" (ngSubmit)="handleSubmit($event)" class="qui-form-layout__col-center flex flex-col gap-400" id="quote-form">
  @if (allFieldsLocked()) {
    <app-locked-quote-alert></app-locked-quote-alert>
  }
  <app-form-card title="What year was your roof built or completely replaced?" icon="lucideHouse" formDisplayAddress="{{ formDisplayAddress }}">
    <app-text-input
      id="roof-year"
      name="roofYear"
      [required]="true"
      type="text"
      inputmode="numeric"
      minlength="4"
      pattern="\d{4}"
      min="0"
      data-testid="roof-year-input"
      maxlength="4"
      [readonly]="isFieldLocked('roofYear')"
      [maskito]="fourDigitInputMask"
      [control]="roofYearForm.controls.roofYear"
    >
      <ng-template>
        @if (roofYearForm.controls.roofYear.errors; as errors) {
          @if (errors['required']) {
            Please enter a roof year
          } @else if (errors['minlength'] || errors['pattern']) {
            Please enter a valid 4-digit year
          } @else if (errors['roofYearBeforeYearBuilt']) {
            <span>
              You've entered a roof year prior to the house's construction year. If this is incorrect, please call us at
              <a href="tel:{{ phoneNumber }}" class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary">{{ phoneNumber | phone }}</a
              >.
            </span>
          } @else {
            An error occurred.
          }
        }
      </ng-template>
    </app-text-input>
  </app-form-card>
</form>
