import type { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';

import type { PolicyTypeQuoteFormInput } from './policy-type.types';
import type { QuoteDraftSelectorState } from '../../../global-store/quote-draft';

export function policyTypeQuoteFormSelector(store: QuoteDraftSelectorState): PolicyTypeQuoteFormInput {
  const policyType = store.policyType();
  return { policyType };
}

export function policyTypeQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store['policy-type']();
}
