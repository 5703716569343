import { AbstractControl, ValidationErrors } from '@angular/forms';
import { addYears, isBefore, isEqual, isValid, parse, startOfDay } from 'date-fns';

/**
 * Validator function to check if the user meets the minimum and maximum age requirements.
 * Maximum age is set to 122 years, based on the oldest verified person.
 * @param control The form control containing the date of birth input.
 * @param getState A function that returns the user's state.
 * @returns A validation error object if validation fails, otherwise null.
 */
export function ageValidator(control: AbstractControl, getState: () => string | undefined): ValidationErrors | null {
  const inputValue = control.value;
  if (!inputValue) {
    return null;
  }

  if (inputValue.length < 10) {
    return null;
  }

  const dateOfBirth = parse(inputValue, 'MM/dd/yyyy', new Date());

  if (!isValid(dateOfBirth)) {
    return { invalidDateFormat: true };
  }

  const state = (getState() || '').toUpperCase();
  const minAge = state === 'AL' ? 19 : 18;

  const today = startOfDay(new Date());
  const dateOfBirthAtMidnight = startOfDay(dateOfBirth);
  const legalAgeDate = addYears(dateOfBirthAtMidnight, minAge);
  const maxAgeDate = addYears(today, -122);

  // Check if age exceeds maximum
  if (isBefore(dateOfBirthAtMidnight, maxAgeDate)) {
    return { overMaxAge: true, maxAge: 122 };
  }

  const hasReachedLegalAge = isBefore(legalAgeDate, today);
  const isExactlyLegalAge = isEqual(legalAgeDate, today);

  if (hasReachedLegalAge || isExactlyLegalAge) {
    return null;
  }

  return { underage: true, requiredAge: minAge };
}
