import { Injectable, inject } from '@angular/core';
import { NgKLoggerService } from '@kin/ngk-logger';

import { LegacyQuotePagesPath } from '../../../common/services/legacy/quote-pages/models/paths.config';
import { QuoteDraftStore } from '../../../global-store/quote-draft';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';

/**
 * Save Form Service
 * - this is an injected service used to save form data with the store
 * - custom methods can be added to this service for side effects or other logic
 */
@Injectable()
export class QuoteFormSaveService {
  private logger = inject(NgKLoggerService);
  private store = inject(QuoteDraftStore);
  /**
   * Save Quote Form Data
   * - generic method to update the Quote Draft state with new date
   */
  public saveFormData(formData: Partial<QuoteDraftState>, legacyShape?: LegacyQuotePagesPath) {
    if (formData) {
      this.store.saveFormData({ formData, legacyPath: legacyShape });
    } else {
      this.logger.error({ context: `QuoteFormSaveService::saveFormData`, message: `no form data found for legacyShape ${legacyShape}`, priority: 'P4' });
    }
  }
}
