import { computed, inject } from '@angular/core';
import { withStorageSync } from '@angular-architects/ngrx-toolkit';
import { SESSION_STORAGE } from '@ng-web-apis/common';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { isDateYYYYMMDD } from 'app/common/utils';
import { StoreStatusError } from 'app/global-store/store-status.model';

import { UnexpectedResponseError } from './legacy-policy-response-mapper.service';
import { createPolicy } from './policies.factory';
import { OtherPolicyFeature, Policy } from './policies.model';
import { FilingRateLockResponse } from '../filing-rate-lock/filing-rate-lock.service';

type StoreStatus = undefined | 'loading' | 'error' | 'success';

type StoreState<T> = {
  status: StoreStatus;
  data: T;
  error: StoreStatusError | null;
};

export type PolicyStoreState = StoreState<Policy>;

const initialState: PolicyStoreState = {
  data: createPolicy(),
  status: undefined,
  error: null,
};

const isAppliedCoverage = (coverage: OtherPolicyFeature) => coverage.value && coverage.value !== '0';
const isSinkholeExclusion = (coverage: OtherPolicyFeature) => coverage.id === 'sinkhole_exclusion' && coverage.value === true;

export const PolicyStore = signalStore(
  { providedIn: 'root' },
  withState<PolicyStoreState>(initialState),
  withComputed((store) => ({
    otherPolicyFeatures: computed(() => {
      const features = store.data().otherPolicyFeatures;
      return Array.isArray(features) ? features.filter((coverage) => isAppliedCoverage(coverage) && !isSinkholeExclusion(coverage)) : [];
    }),
    additionalCoverages: computed(() => {
      const features = store.data().otherPolicyFeatures;
      return Array.isArray(features) ? features.filter((coverage) => !isAppliedCoverage(coverage) || isSinkholeExclusion(coverage)) : [];
    }),
    coverageData: computed(() => {
      const primaryCoverages = store.data().primaryCoverages.reduce(
        (acc, coverage) => ({
          ...acc,
          [coverage.id as string]: coverage.value,
        }),
        {},
      );
      const additionalCoverages = store.data().otherPolicyFeatures.reduce(
        (acc, coverage) => ({
          ...acc,
          [coverage.id as string]: coverage.value,
        }),
        {},
      );
      const deductibles = store.data().deductibles.reduce(
        (acc, deductible) => ({
          ...acc,
          [deductible.id as string]: deductible.value,
        }),
        {},
      );
      const { effectiveDate, firstName, fullName, policyType, premium } = store.data();
      return {
        primaryCoverages,
        additionalCoverages,
        deductibles,
        effectiveDate,
        firstName,
        fullName,
        policyType,
        premium,
      };
    }),
    isLoading: computed(() => store.status() === 'loading'),
    hasError: computed(() => store.status() === 'error'),
  })),
  withMethods((store) => ({
    updateStatus(status: StoreStatus): void {
      patchState(store, { status });
    },
    setData(data: Policy): void {
      patchState(store, { data });
    },
    setError(error: StoreStatusError | null): void {
      patchState(store, {
        status: error ? 'error' : store.status(),
        error: error || null,
      });
    },
    setFilingRateLock(response: FilingRateLockResponse): void {
      if (!response) {
        throw new UnexpectedResponseError('Filing rate lock response is missing');
      }

      if (!response.expiration_date) {
        throw new UnexpectedResponseError('Filing rate lock expiration date is missing');
      }

      if (!isDateYYYYMMDD(response.expiration_date)) {
        throw new UnexpectedResponseError('Filing rate lock expiration date is not in YYYY-MM-DD format');
      }

      patchState(store, { data: { ...store.data(), filingRateLock: { expirationDate: response.expiration_date } } });
    },
  })),
  withStorageSync({
    key: 'policies',
    autoSync: true,
    storage: () => inject(SESSION_STORAGE),
  }),
);
