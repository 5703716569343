import { ChangeDetectionStrategy, Component, OnInit, Signal, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoOptions } from '@maskito/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideHouse } from '@ng-icons/lucide';

import { ReadonlyRadioDirective } from 'app/common/directives/readonly-radio.directive';
import { Lockable, WithLockableFields } from 'app/create-quote/form-locking';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';

import { YearBuiltQuoteFormInput } from './year-built.types';
import { TextInputComponent } from '../../../../common/components/forms/text-input/text-input.component';
import { PHONE_NUMBERS } from '../../../../common/constants/contact-constants';
import { PhonePipe } from '../../../../common/pipes/phone-number/phone.pipe';
import { QuoteDraftState } from '../../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../../form-config/quote-form-decorator';
import { LockedQuoteAlertComponent } from '../../../form-locking/locked-quote-alert.component';
import { FormCardComponent } from '../../shared/form-card.component';
import { fourDigitInputMask } from '../../shared/masks';
import { RoofYearQuoteFormInput } from '../roof-year/roof-year.types';

@Component({
  selector: 'app-year-built',
  imports: [ReactiveFormsModule, NgIconComponent, FormCardComponent, TextInputComponent, MaskitoDirective, ReadonlyRadioDirective, LockedQuoteAlertComponent],
  providers: [provideIcons({ lucideHouse, lucideCircleAlert })],
  templateUrl: './year-built.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/property-details/year-built',
})
export class QuoteFormYearBuiltComponent extends BaseQuoteForm<YearBuiltQuoteFormInput> implements OnInit, Lockable {
  private optionsStore = inject(QuoteFormOptionsStore);

  public formDataIn: YearBuiltQuoteFormInput = this.quoteDraftStore.yearBuiltQuoteFormSelector();
  private roofYear: RoofYearQuoteFormInput = this.quoteDraftStore.roofYearQuoteFormSelector();

  public formDefinition = {
    yearBuilt: new FormControl<number | undefined>(undefined, {
      validators: [Validators.min(1899), Validators.max(new Date().getFullYear() + 1), this.isYearBuiltBeforeRoofYear()],
      updateOn: 'blur',
    }),
    sqFt: new FormControl<number | undefined>(undefined, {
      validators: [Validators.min(10), Validators.max(10000)],
      updateOn: 'blur',
    }),
    stories: new FormControl<number | undefined>(undefined, {
      validators: [Validators.min(1), Validators.max(100)],
      updateOn: 'blur',
    }),
    pool: new FormControl<boolean | undefined>(undefined),
  };
  public yearBuiltForm = new FormGroup(this.formDefinition);

  public yearBuiltOptions = this.optionsStore.yearBuiltQuoteFormOptionsSelector();

  public showError(control: FormControl): boolean {
    return control.invalid && (control.dirty || control.touched);
  }

  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  // return only the controls that have required validators
  public getFormControls() {
    return Object.fromEntries(Object.entries(this.yearBuiltForm.controls).filter(([_, control]) => control.hasValidator(Validators.required)));
  }

  readonly fourDigitInputMask: MaskitoOptions = fourDigitInputMask;

  public ngOnInit() {
    if (this.formDataIn) {
      this.yearBuiltForm.controls.yearBuilt.setValue(this.formDataIn.yearBuilt);
      this.yearBuiltForm.controls.sqFt.setValue(this.formDataIn.sqFt);
      this.yearBuiltForm.controls.stories.setValue(this.formDataIn.stories);
      this.yearBuiltForm.controls.pool.setValue(this.formDataIn.pool);
    }

    this.setConditionalRequiredValidator('yearBuilt', 'year_built');
    this.setConditionalRequiredValidator('sqFt', 'sq_ft');
    this.setConditionalRequiredValidator('stories', 'stories');
    this.setConditionalRequiredValidator('pool', 'pool');
  }

  private setConditionalRequiredValidator(controlName: string, optionName: string) {
    const control = this.yearBuiltForm.get(controlName);
    if (control) {
      if (this.hasOption(optionName)) {
        control.addValidators(Validators.required);
      } else {
        control.clearValidators();
      }
      control.updateValueAndValidity();
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'yearBuilt' | 'sqFt' | 'stories' | 'pool'> {
    const { controls } = this.yearBuiltForm;
    return {
      yearBuilt: controls.yearBuilt.value ?? undefined,
      sqFt: controls.sqFt.value ?? undefined,
      stories: controls.stories.value ?? undefined,
      pool: controls.pool.value ?? undefined,
    };
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.yearBuiltForm.markAllAsTouched();
    if (this.yearBuiltForm.valid) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        super.saveFormData(this.getFormValues());
      }
    }
  }

  public hasOption(id: string): boolean {
    if (!this.yearBuiltOptions) {
      return false;
    }
    return this.yearBuiltOptions.find((option) => option.id === id) !== undefined;
  }

  private isYearBuiltBeforeRoofYear(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const yearBuilt = Number(control.value);
      const roofYear = this.roofYear?.roofYear;

      if (Number.isNaN(yearBuilt) || !roofYear) {
        return null;
      }

      return yearBuilt < Number(roofYear) ? { yearBuiltBeforeRoofYear: true } : null;
    };
  }

  public errorMessages = {
    yearBuilt: {
      required: 'Please enter the year built',
      min: 'This field is too low',
      max: 'This field is too high',
      yearBuiltBeforeRoofYear: `
      You've entered a year prior to the roof's construction year.  
      If this is incorrect, please call us at ${new PhonePipe().transform(PHONE_NUMBERS.sales)}.
      `,
    },
    sqFt: {
      required: 'Please enter the number of livable square feet',
      min: 'Livable square feet must be greater than 10',
      max: "Livable square feet can't be more than 10,000",
    },
    stories: {
      required: 'Please enter the number of stories',
      min: 'Number of stories must be greater than 0',
      max: "Number of stories can't be more than 100",
    },
    pool: {
      required: 'Please make a selection',
    },
  };
}
