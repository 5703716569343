import type { QuoteDraftSelectorState } from 'app/global-store/quote-draft';

import { LegacyPackageTitle } from '../forms/compare/compare.types';

export interface QuoteReviewSelectorState {
  firstName: string | undefined;
  lastName: string | undefined;
  selectedPackage: LegacyPackageTitle | undefined;
  fullName: string | undefined;
  addressStateAbbreviated: string | undefined;
}

export function quoteReviewSelector(store: QuoteDraftSelectorState): QuoteReviewSelectorState {
  const { applicant } = store;
  const { firstName, lastName } = applicant() || {};
  const selectedPackage = store.selectedPackage() || undefined;
  const addressStateAbbreviated = store.address()?.state;
  let fullName;
  if (firstName && lastName) {
    fullName = `${firstName} ${lastName}`;
  }

  return { firstName, lastName, selectedPackage, addressStateAbbreviated, fullName };
}
