@if (isAutoAdvanceEnabled()) {
  <p class="sr-only">Selecting an option will immediately submit the form and take you to the next page. You cannot navigate backwards and change your selection.</p>
}
<form [formGroup]="formGrp" (ngSubmit)="handleSubmit($event)" id="quote-form" class="qui-form-layout__col-center flex flex-col gap-400">
  @if (allFieldsLocked()) {
    <app-locked-quote-alert></app-locked-quote-alert>
  }
  <app-form-card title="Did your insurance expire over 30 days ago?" icon="lucideHouse" formDisplayAddress="{{ formDisplayAddress }}">
    <div class="mt-600 grid grid-cols-1 gap-x-400 gap-y-400 md:gap-y-600">
      <fieldset class="form-group__fieldset" [appReadonlyRadioGroup]="isFieldLocked('isInsuranceExpiredMoreThan30Days')" [appFocusOnlyRadioGroup]="isAutoAdvanceEnabled()">
        <legend class="sr-only">Did your insurance expire over 30 days ago?</legend>
        <div class="grid grid-cols-2 gap-200 text-center">
          <label class="radio-card radio-card--block group focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showFormRequiredError()" data-testid="insurance-expiration-yes">
            <div class="radio-card__header flex flex-col gap-100">
              <span class="radio-card__title w-full font-medium leading-200 group-has-[:checked]:font-bold">Yes</span>
            </div>
            <input [value]="true" class="radio-card__control peer outline-none" type="radio" formControlName="isInsuranceExpiredMoreThan30Days" />
          </label>
          <label class="radio-card radio-card--block group focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showFormRequiredError()" data-testid="insurance-expiration-no">
            <div class="radio-card__header flex flex-col gap-100">
              <span class="radio-card__title w-full font-medium leading-200 group-has-[:checked]:font-bold">No</span>
            </div>
            <input [value]="false" class="radio-card__control peer outline-none" type="radio" formControlName="isInsuranceExpiredMoreThan30Days" />
          </label>
        </div>
      </fieldset>

      @if (showFormRequiredError()) {
        <div class="fs-body-md fw-medium error-message flex items-center gap-100 text-warning" role="alert" data-testid="form-error-message">
          <ng-icon name="lucideCircleAlert" size="16" class="!overflow-visible" aria-hidden="true"></ng-icon>
          Please make a selection
        </div>
      }
    </div>
  </app-form-card>
</form>
