<div class="qui-form-layout__col-center flex flex-col gap-400">
  @if (allFieldsLocked()) {
    <app-locked-quote-alert></app-locked-quote-alert>
  }
  <app-form-card title="We have a few more questions about your property." icon="lucideHouse" formDisplayAddress="{{ formDisplayAddress }}">
    <form [formGroup]="propertyInformationForm" (ngSubmit)="handleSubmit($event)" id="quote-form" class="mt-600">
      <div class="flex flex-col gap-600">
        @if (hasOption('within_park')) {
          <fieldset class="form-group__fieldset" [appReadonlyRadioGroup]="isFieldLocked('withinPark')">
            <legend
              [class]="'input__label ' + (propertyInformationForm.controls.withinPark.touched ? 'text-neutral-900' : 'text-neutral-700')"
              [class.text-warning]="showError(propertyInformationForm.controls.withinPark)"
            >
              Is your home in a manufactured home park?
            </legend>

            <div class="mt-3 flex flex-row gap-100 sm:gap-400">
              <label
                class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary"
                [class.radio-card--invalid]="showError(propertyInformationForm.controls.withinPark)"
                data-testid="within-park-yes"
              >
                <input class="radio-card__control peer outline-none" type="radio" id="within-park-yes" [value]="true" formControlName="withinPark" />
                <span for="within-park-yes">Yes</span>
              </label>
              <label
                class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary"
                [class.radio-card--invalid]="showError(propertyInformationForm.controls.withinPark)"
                data-testid="within-park-no"
              >
                <input class="radio-card__control peer outline-none" type="radio" id="within-park-no" [value]="false" formControlName="withinPark" />
                <span for="within-park-no">No</span>
              </label>
            </div>

            <div
              id="within-park-message"
              [class]="'form-help ' + (propertyInformationForm.controls.withinPark.touched ? 'text-neutral-900' : 'text-neutral-700')"
              aria-live="polite"
              aria-atomic="true"
            >
              @if (showError(propertyInformationForm.controls.withinPark)) {
                <div class="form-help--invalid flex items-center gap-100" id="within-park-error">
                  <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>
                  {{ errorMessages.withinPark.required }}
                </div>
              }
            </div>
          </fieldset>
        }

        @if (hasOption('bathrooms')) {
          <app-text-input
            id="bathrooms"
            label="How many bathrooms are in the main dwelling of your property?"
            type="number"
            [control]="propertyInformationForm.controls.bathrooms"
            min="0"
            [readonly]="isFieldLocked('bathrooms')"
            step="1"
            [errorMessages]="errorMessages.bathrooms"
            [required]="true"
            data-testid="bathrooms"
            helpText='Please include both full and half bathrooms. For example, if you have 1 full bath and one half bath, you would answer "2".'
          />
        }

        @if (hasOption('acreage')) {
          <app-text-input
            id="acreage"
            label="How many acres of land does this property include?"
            type="number"
            [control]="propertyInformationForm.controls.acreage"
            min="0"
            step="1"
            [readonly]="isFieldLocked('acreage')"
            [required]="true"
            [errorMessages]="errorMessages.acreage"
            data-testid="acreage"
          />
        }

        @if (hasOption('purchase_date')) {
          <app-text-input
            id="purchase-date"
            type="date"
            [readonly]="isFieldLocked('purchaseDate')"
            label="When did you purchase this home?"
            [required]="true"
            [control]="propertyInformationForm.controls.purchaseDate"
            helpText="Please enter the purchase date"
            [errorMessages]="errorMessages.purchaseDate"
            data-testid="purchase-date"
          ></app-text-input>
        }

        @if (hasOption('roof_type') && getRoofTypeOptions().length > 0) {
          <div class="form-control">
            <label class="select" [class.select--invalid]="showError(propertyInformationForm.controls.roofType)">
              <span [class]="'select__label ' + (propertyInformationForm.controls.roofType.touched ? 'text-neutral-900' : 'text-neutral-700')">What type of roof does the home have?</span>
              <select formControlName="roofType" class="select__control" required [appReadonlySelect]="isFieldLocked('roofType')" data-testid="roof-type">
                @for (roofType of getRoofTypeOptions(); track roofType.value) {
                  <option [value]="roofType.value">{{ roofType.label }}</option>
                }
              </select>
            </label>
            <div class="form-help" aria-live="polite" aria-atomic="true">
              @if (showError(propertyInformationForm.controls.roofType)) {
                <div class="form-help--invalid flex items-center gap-100" id="roof-type-error">
                  <ng-icon name="lucideCircleAlert" aria-hidden="true" class="!overflow-visible"></ng-icon>
                  {{ errorMessages.roofType.required }}
                </div>
              }
            </div>
          </div>
        }

        @if (hasOption('chimney')) {
          <fieldset class="form-group__fieldset" [appReadonlyRadioGroup]="isFieldLocked('chimney')">
            <legend
              [class]="'input__label ' + (propertyInformationForm.controls.chimney.touched ? 'text-neutral-900' : 'text-neutral-700')"
              [class.text-warning]="showError(propertyInformationForm.controls.chimney)"
            >
              Does your home have a chimney?
            </legend>

            <div class="mt-3 flex flex-row gap-100 sm:gap-400">
              <label
                class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary"
                [class.radio-card--invalid]="showError(propertyInformationForm.controls.chimney)"
                data-testid="chimney-yes"
              >
                <input class="radio-card__control peer outline-none" type="radio" id="chimney-yes" [value]="true" name="chimney" formControlName="chimney" />
                <span for="chimney-yes">Yes</span>
              </label>
              <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showError(propertyInformationForm.controls.chimney)">
                <input class="radio-card__control peer outline-none" type="radio" id="chimney-no" [value]="false" name="chimney" formControlName="chimney" />
                <span for="chimney-no">No</span>
              </label>
            </div>

            <div id="'chimney-message'" [class]="'form-help ' + (propertyInformationForm.controls.chimney.touched ? 'text-neutral-900' : 'text-neutral-700')" aria-live="polite" aria-atomic="true">
              @if (showError(propertyInformationForm.controls.chimney)) {
                <div class="form-help--invalid flex items-center gap-100" id="chimney-error">
                  <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>
                  {{ errorMessages.chimney.required }}
                </div>
              }
            </div>
          </fieldset>
        }

        @if (hasOption('roof_solar_panels')) {
          <fieldset class="form-group__fieldset" [appReadonlyRadioGroup]="isFieldLocked('roofHasSolarPanels')">
            <legend
              [class]="'input__label ' + (propertyInformationForm.controls.roofHasSolarPanels.touched ? 'text-neutral-900' : 'text-neutral-700')"
              [class.text-warning]="showError(propertyInformationForm.controls.roofHasSolarPanels)"
            >
              Does your home have solar panels?
            </legend>

            <div class="mt-3 flex flex-row gap-100 sm:gap-400">
              <label
                class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary"
                [class.radio-card--invalid]="showError(propertyInformationForm.controls.roofHasSolarPanels)"
                data-testid="roof-solar-panels-yes"
              >
                <input class="radio-card__control peer outline-none" type="radio" id="roof-solar-panels-yes" [value]="true" name="roofHasSolarPanels" formControlName="roofHasSolarPanels" />
                <span for="roof-solar-panels-yes">Yes</span>
              </label>
              <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showError(propertyInformationForm.controls.roofHasSolarPanels)">
                <input class="radio-card__control peer outline-none" type="radio" id="roof-solar-panels-no" [value]="false" name="roofHasSolarPanels" formControlName="roofHasSolarPanels" />
                <span for="roof-solar-panels-no">No</span>
              </label>
            </div>

            <div
              id="'roof-solar-panels-message'"
              [class]="'form-help ' + (propertyInformationForm.controls.roofHasSolarPanels.touched ? 'text-neutral-900' : 'text-neutral-700')"
              aria-live="polite"
              aria-atomic="true"
            >
              @if (showError(propertyInformationForm.controls.roofHasSolarPanels)) {
                <div class="form-help--invalid flex items-center gap-100" id="roof-solar-panels-error">
                  <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>
                  {{ errorMessages.roofHasSolarPanels.required }}
                </div>
              }
            </div>
          </fieldset>
        }

        @if (hasOption('solar_panel_status') && showSolarPanelStatusQuestion()) {
          <fieldset id="solar-panel-status-question" #solarPanelStatusQuestion class="form-group__fieldset" [appReadonlyRadioGroup]="isFieldLocked('solarPanelStatus')">
            <legend
              [class]="'input__label ' + (propertyInformationForm.controls.solarPanelStatus.touched ? 'text-neutral-900' : 'text-neutral-700')"
              [class.text-warning]="showError(propertyInformationForm.controls.solarPanelStatus)"
            >
              Are the solar panels on your home owned or leased?
            </legend>

            <div class="mt-3 flex flex-row gap-100 sm:gap-400">
              <label
                class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary"
                [class.radio-card--invalid]="showError(propertyInformationForm.controls.solarPanelStatus)"
                data-testid="solar-panel-status-owned"
              >
                <input class="radio-card__control peer outline-none" type="radio" id="solar-panel-status-owned" value="owned" name="solarPanelStatus" formControlName="solarPanelStatus" />
                <span for="solar-panel-status-owned">Owned</span>
              </label>
              <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showError(propertyInformationForm.controls.solarPanelStatus)">
                <input class="radio-card__control peer outline-none" type="radio" id="solar-panel-status-leased" value="leased" name="solarPanelStatus" formControlName="solarPanelStatus" />
                <span for="solar-panel-status-leased">Leased</span>
              </label>
            </div>

            <div
              id="'solar-panel-status-message'"
              [class]="'form-help ' + (propertyInformationForm.controls.solarPanelStatus.touched ? 'text-neutral-900' : 'text-neutral-700')"
              aria-live="polite"
              aria-atomic="true"
            >
              @if (showError(propertyInformationForm.controls.solarPanelStatus)) {
                <div class="form-help--invalid flex items-center gap-100" id="solar-panel-status-error">
                  <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>
                  {{ errorMessages.solarPanelStatus.required }}
                </div>
              }
            </div>
          </fieldset>
        }
      </div>
    </form>
  </app-form-card>
</div>
