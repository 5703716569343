import { CoApplicantInfoQuoteFormInput } from './co-applicant-info.types';
import { utilToUiDate } from '../../../common/utils/iso-date-type/to-iso-date';
import type { QuoteDraftSelectorState } from '../../../global-store/quote-draft';

export function coApplicantInfoQuoteFormSelector(store: QuoteDraftSelectorState): CoApplicantInfoQuoteFormInput {
  const { coApplicant } = store;

  // update the date string
  const lastName = coApplicant()?.lastName ?? '';
  const firstName = coApplicant()?.firstName ?? '';
  const dob = coApplicant()?.dob;
  const formattedDob = dob ? utilToUiDate(dob) : '';

  return { firstName, lastName, dob: formattedDob ?? '' };
}
