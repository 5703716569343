import { AbstractControl, ValidatorFn } from '@angular/forms';
import { add } from 'date-fns';
/**
 * Today's date at midnight as a Date.
 * @returns {Date} - The date object with the time set to midnight.
 */
export function today() {
  const todaysDate = new Date();
  todaysDate.setHours(0, 0, 0, 0);
  return todaysDate;
}

/**
 * The max date that the user can select for the policy start date is 60 days from today.
 * @returns {Date} - The date object with the time set to midnight.
 */
export function sixtyDaysFromToday() {
  return add(today(), { days: 60 });
}

/**
 * The max date that the user can select for the policy start date is 60 days from today;
 * this function is used for tests to make sure a user cannot select a date that is over 60 days from today.
 * @returns {Date} - The date object with the time set to midnight.
 */
export function sixtyOneDaysFromToday() {
  return add(today(), { days: 61 });
}

/**
 * Preselected date for policy start date is 14 days from today's date.
 * @returns {Date} - The date object with the time set to midnight.
 */
export function fourteenDaysFromToday(): Date {
  return add(today(), { days: 14 });
}

/**
 *
 * @param {string | null} minDate The minimum date that the user can select. `null` is a valid parameter if there is no min date.
 * @param {string | null} maxDate The maximum date that the user can select. `null` is a valid parameter if there is no min date.
 * @returns {ValidatorFn} - A validator function that checks if the date is within the min and max date range.
 * This is used to validate custom date ranges in the component's form.
 */
export function dateRangeValidator(minDate: string | null, maxDate: string | null): ValidatorFn {
  return (control: AbstractControl): DateRangeValidationError | null => {
    const { value } = control;
    if (!value) {
      return null;
    }

    const dateValue = new Date(value);
    if (Number.isNaN(dateValue.getTime())) {
      return { dateRange: { minDate, maxDate, actual: value } };
    }

    const min = minDate ? new Date(minDate) : null;
    const max = maxDate ? new Date(maxDate) : null;

    if ((min && dateValue < min) || (max && dateValue > max)) {
      return { dateRange: { minDate, maxDate, actual: value } };
    }

    return null;
  };
}

export interface DateRangeValidationError {
  dateRange: {
    minDate: string | null;
    maxDate: string | null;
    actual: string;
  };
}
