@if (isAutoAdvanceEnabled()) {
  <p class="sr-only">Selecting an option will immediately submit the form and take you to the next page. You cannot navigate backwards and change your selection.</p>
}
<form [formGroup]="residencyForm" (submit)="handleSubmit($event)" id="quote-form" class="qui-form-layout__col-center flex w-full flex-col gap-400">
  @if (allFieldsLocked()) {
    <app-locked-quote-alert></app-locked-quote-alert>
  }
  <app-form-card title="Do you live on this property for most of the year?" icon="lucideHouse" class="" formDisplayAddress="{{ formDisplayAddress }}">
    <fieldset class="form-group__fieldset mt-400" [appReadonlyRadioGroup]="isFieldLocked('residency')" [appFocusOnlyRadioGroup]="isAutoAdvanceEnabled()">
      <legend class="sr-only">Select your residency status</legend>
      <div class="grid gap-200 md:grid-cols-3">
        @for (residencyOption of residencyOptions; track residencyOption) {
          <label
            class="radio-card radio-card--block group focus-within:ring-2 focus-within:ring-primary"
            [class.radio-card--invalid]="showFormRequiredError()"
            [for]="residencyOption.id"
            [attr.data-testid]="'residency-' + residencyOption.id"
          >
            <div class="radio-card__header flex flex-col gap-100">
              <span class="radio-card__title w-full font-medium leading-200 group-has-[:checked]:font-bold">{{ residencyOption.label }} </span>
            </div>
            <input class="radio-card__control peer outline-none" type="radio" [id]="residencyOption.id" [value]="residencyOption.value" formControlName="residency" />
          </label>
        }
      </div>
    </fieldset>

    <div aria-live="polite" aria-atomic="true">
      @if (showFormRequiredError()) {
        <div class="form-help form-help--invalid error-message flex items-center gap-100">
          <ng-icon name="lucideCircleAlert" class="" aria-hidden="true"></ng-icon>
          Please select a residency option.
        </div>
      }
    </div>
  </app-form-card>
</form>
