import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AppContextInterface } from './app-config.model';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  /**
   * used to save the startup context in the event overrideContext is used.
   */
  private _startUpContext!: AppContextInterface;

  /**
   * appContext should not be mutable after startup
   * it can be mutated via overrideContext method, but that makes the change intentional
   */
  private _appContext: AppContextInterface | null = null;

  /**
   * immutable(ish) application context that is set on startup
   * only expose getter and require a method to update so update is intentional
   */
  public get appContext() {
    return this._appContext;
  }

  /**
   * expose observable to indicate if/when context gets overridden
   * this could be important if something needs reloaded
   */
  public isContextOverridden$ = new BehaviorSubject(false);

  /**
   * meant to only be called once during startup.
   * this should not change the appContext after it is set
   * sets the startup context property as well that should never be changed
   */
  public setStartupContext(newContext: AppContextInterface) {
    if (this._appContext === null) {
      this._appContext = { ...newContext };
      this._startUpContext = { ...newContext };
    }
  }

  /**
   * This is used for overriding the context in lower environments
   * since setStartupContext only allows it once.
   * The method is primarily used by the app-kin-endpoint-tester.
   */
  public overrideContext(newContext: AppContextInterface) {
    if (this._appContext?.notProd) {
      this._appContext = { ...newContext };
      this.isContextOverridden$.next(true);
      // eslint-disable-next-line no-console
      console.warn(`environment overridden to: ${this.appContext?.apiRoot.quoteV1}`);
    }
  }

  /**
   * This is used for restoring the context in lower environments
   * in the even overrideContext is used.
   */
  public restoreContext() {
    if (this._appContext?.notProd) {
      this._appContext = { ...this._startUpContext };
      this.isContextOverridden$.next(false);
      // eslint-disable-next-line no-console
      console.warn(`environment restored to: ${this.appContext?.apiRoot.quoteV1}`);
    }
  }
}
