import type { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';

import type { FoundationTypeQuoteFormInput } from './foundation.types';
import type { QuoteDraftSelectorState } from '../../../../global-store/quote-draft';

export function foundationTypeQuoteFormSelector(store: QuoteDraftSelectorState): FoundationTypeQuoteFormInput {
  const foundationType = store.foundationType();
  return { foundationType };
}

export function foundationTypeQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store.foundation();
}
