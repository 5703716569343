import { NgKLoggerService } from '@kin/ngk-logger';

import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';

const LEGACY_PAGES_API_POLICY_START_DATE_COMPONENT_ATTRIBUTE_NAMES = ['effective_date'];

/**
 * Checks the dot-com pages_controller response from polling.
 * Does not return anything, but logs an error if the component with `attribute_name` of `effective_date` is not found.
 * @param {LegacyApiPagesComponent[]} components
 * @param {NgKLoggerService} logger
 */
export function getComponentsForLegacyPagesApiPolicyStartDate(components: LegacyApiPagesComponent[], logger: NgKLoggerService): undefined {
  const policyStartDateComponents = components.filter((component) => LEGACY_PAGES_API_POLICY_START_DATE_COMPONENT_ATTRIBUTE_NAMES.includes(component.attribute_name));
  if (policyStartDateComponents.length === 0 || policyStartDateComponents.length !== LEGACY_PAGES_API_POLICY_START_DATE_COMPONENT_ATTRIBUTE_NAMES.length) {
    logger.error({ message: 'could not find components for policy-start-date page', context: 'getComponentsForLegacyPagesApiPolicyStartDate', priority: 'P3' });
  }
}
