import { Injectable, inject } from '@angular/core';
import { NgKLoggerService } from '@kin/ngk-logger';

import { Endorsement } from 'app/common/services/legacy/policies/policies.model';
import { LegacyApiCoverage } from 'app/common/services/legacy/policies/policies.service';

@Injectable({
  providedIn: 'root',
})
export class FloodProtectionMapperService {
  private logger = inject(NgKLoggerService);

  mapFloodProtection(coverages: LegacyApiCoverage[] | undefined | null): Endorsement[] {
    if (!coverages || !Array.isArray(coverages)) {
      return [];
    }

    return coverages.filter(this.isValidCoverage).map((coverage) => this.mapSingleCoverage(coverage));
  }

  private isValidCoverage(coverage: LegacyApiCoverage): boolean {
    return Boolean(coverage && coverage.component && typeof coverage.component.attribute_name === 'string' && typeof coverage.editable === 'boolean' && typeof coverage.value === 'boolean');
  }

  private mapSingleCoverage(coverage: LegacyApiCoverage): Endorsement {
    return {
      id: coverage.component.attribute_name,
      isEditable: coverage.editable ?? false,
      value: typeof coverage.value === 'boolean' ? coverage.value : undefined,
    };
  }
}
