import type { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';

import type { RoofShapeQuoteFormInput } from './roof-shape.types';
import type { QuoteDraftSelectorState } from '../../../../global-store/quote-draft';

export function roofShapeQuoteFormSelector(store: QuoteDraftSelectorState): RoofShapeQuoteFormInput {
  const roofShape = store.roofShape();
  return { roofShape };
}

export function roofShapeQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store['roof-shape']();
}
