import { NgKLoggerService } from '@kin/ngk-logger';
import { catchError, filter, of, take, tap } from 'rxjs';

import { FullstoryService } from 'app/common/services/fullstory/fullstory.service';
import { GuidService } from 'app/common/services/guid/guid.service';
import { SplitIOService } from 'app/common/services/splitIO/splitIO.service';

import pkgJson from '../../../../package.json';
import { AppApiRootList, AppEnvironment } from '../app-config.model';
import { AppConfigService } from '../app-config.service';
import { quotingAppApiConfig, quotingProdApiRoot } from '../configurations/api-config';
import { getAppEnvironment } from '../configurations/env-config';
import { fullstoryEnvConfig } from '../configurations/fullstory-config';
import { smartyStreetsEnvConfig } from '../configurations/smartyStreets-config';
import { splitIOEnvConfig } from '../configurations/splitIO-config';

type ResolvedContext = {
  env: AppEnvironment;
  apiRoot: AppApiRootList;
};

export function appInitializerFactory(
  appConfigService: AppConfigService,
  injectedWindow: Window,
  logger: NgKLoggerService,
  fullstoryService: FullstoryService,
  guidService: GuidService,
  splitIOService: SplitIOService,
  sessionStorage: Storage,
): () => void {
  return () => {
    const clientHost = injectedWindow?.location?.host; // get client host the app was loaded into
    const env = getAppEnvironment(clientHost); // associate with a known env
    const apiRoot = quotingAppApiConfig.get(env!);
    const buildVersion = pkgJson.version ?? '0.0.0-unknown';

    // URLs where we log to datadog as opposed to just the console; do not enable for localhost or preview envs
    const activeDatadogUrls = ['quoting.kin.haus', 'quoting.kin.com', 'quoting.kin.insure', 'quoting-uat.kin.haus'];

    let resolvedContext: ResolvedContext;

    if (!env || !apiRoot) {
      // Force datadog to be active since we are defaulting to production
      initLogger(logger, [clientHost], buildVersion);
      // If either env or apiRoot are undefined, we don't know where the app was loaded. Throw error and default to production;
      // this needs to be after we init logger so we get the unknown env alert
      logger.error({
        priority: 'P1',
        context: 'AppInitializerFactory',
        message: `Unknown Environment Detected - Host: ${clientHost}, Origin: ${window.location.origin}, Location: ${JSON.stringify(window.location)}`,
      });

      resolvedContext = defaultToProduction(logger);
    } else {
      resolvedContext = { env, apiRoot };
      initLogger(logger, activeDatadogUrls, buildVersion);
    }

    const fullstoryOrgID = fullstoryEnvConfig.get(resolvedContext.env)!;
    fullstoryService.initialize(resolvedContext.env, fullstoryOrgID, guidService.guid);

    const splitIOKey = splitIOEnvConfig.get(resolvedContext.env)!;
    splitIOService.createSplitIOClient({ splitIOClientKey: splitIOKey, userIdentifier: guidService.guid });

    // If user has updated the API root URL through the Debug Panel, use that value pulled from session storage.
    // Otherwise, use the value from the environment config.
    const apiRootFromSessionStorage = sessionStorage.getItem('updatedApiRoot');
    if (apiRootFromSessionStorage && resolvedContext.env !== 'production') {
      resolvedContext.apiRoot.quoteV1 = apiRootFromSessionStorage;
    }

    // Add setting to the base configuration
    appConfigService.setStartupContext({
      buildVersion,
      environment: resolvedContext.env,
      apiRoot: resolvedContext.apiRoot,
      notProd: resolvedContext.env !== 'production',
      smartyStreetsKey: smartyStreetsEnvConfig.get(resolvedContext.env)!,
      fullstoryOrgID,
      splitIOKey,
    });

    return splitIOService.clientStatus$.pipe(
      tap((status) => {
        if (status === 'failed') {
          logger.error({ message: 'SplitIO client failed to initialize', priority: 'P3', context: 'appInitializerFactory' });
        }
      }),
      filter((status) => status === 'ready' || status === 'failed'),
      take(1),
      catchError(() => of(true)),
    );
  };
}

/**
 * Abstracted function to clarify logic for defaulting to production
 */
function defaultToProduction(logger: NgKLoggerService): ResolvedContext {
  logger.error({ message: 'Unknown Host, Using Production', priority: 'P3', context: 'appInitializerFactory' });
  return { env: 'production', apiRoot: { quoteV1: quotingProdApiRoot } };
}

function initLogger(logger: NgKLoggerService, activeUrls: string[], buildVersion: string) {
  // Configure Datadog RUM
  logger.initializeDataDog({
    applicationId: 'bc4c19ab-44cc-482b-8a1f-8ccd9d1f1083',
    clientToken: 'pub680814101f24f903b1d14a63f78efcca',
    service: 'quoting-ui', // example service
    version: buildVersion,
    activeUrls,
  });
}
