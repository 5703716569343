/**
 * Validates whether the date entered is past current date
 */
export function isDateExpired(date: string): boolean {
  const parsedDate = Date.parse(date);
  return Number.isNaN(parsedDate) || new Date() > new Date(date);
}

/**
 * Validates whether the date entered is in YYYY-MM-DD format.
 * This is the common format used in V1 API responses.
 */
export function isDateYYYYMMDD(date: string): boolean {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  return dateRegex.test(date);
}
