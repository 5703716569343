import { NgKLoggerService } from '@kin/ngk-logger';

import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';

export function getOptionsForLegacyPagesApiUnitNumberEntryComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): undefined {
  const unitNumberEntryComponent = components.find((component) => component.attribute_name === 'unit_number_entry');
  if (!unitNumberEntryComponent) {
    logger.error({ message: 'could not find component for unit_number_entry', context: 'getOptionsForLegacyPagesApiUnitNumberEntryComponent', priority: 'P3' });
  }
}
