import { Injectable, inject } from '@angular/core';

import { QuoteDraftStore } from 'app/global-store/quote-draft';

import { mergeSubmittedFields } from './form-lock.util';

@Injectable({
  providedIn: 'root',
})
export class FormLockService {
  private quoteDraftStore = inject(QuoteDraftStore);

  markFieldsAsSubmitted(fields: string[], formKey: string): void {
    const currentSubmittedFields = this.quoteDraftStore.submittedFields();

    this.quoteDraftStore.patchStore({
      submittedFields: mergeSubmittedFields(currentSubmittedFields, formKey, fields),
    });
  }

  isFieldSubmitted(fieldName: string, formKey: string): boolean {
    return this.quoteDraftStore.submittedFields()[formKey]?.[fieldName] ?? false;
  }
}
