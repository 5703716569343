import type { QuoteDraftSelectorState } from 'app/global-store/quote-draft';
import type { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';

import type { PropertyInformationQuoteFormInput } from './property-information.types';

export function propertyInformationQuoteFormSelector(store: QuoteDraftSelectorState): PropertyInformationQuoteFormInput {
  const withinPark = store.withinPark();
  const bathrooms = store.bathrooms();
  const acreage = store.acreage();
  const roofType = store.roofType();
  const purchaseDate = store.purchaseDate();
  const chimney = store.chimney();
  const roofHasSolarPanels = store.roofHasSolarPanels();
  const solarPanelStatus = store.solarPanelStatus();

  return {
    withinPark,
    bathrooms,
    acreage,
    purchaseDate,
    roofType,
    chimney,
    roofHasSolarPanels,
    solarPanelStatus,
  };
}

export function propertyInformationQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store['property-information']();
}
