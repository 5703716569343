import type { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';

import type { InsuranceStatusQuoteFormInput } from './insurance-status.types';
import type { QuoteDraftSelectorState } from '../../../global-store/quote-draft';

export function insuranceStatusQuoteFormSelector(store: QuoteDraftSelectorState): InsuranceStatusQuoteFormInput {
  const insuranceStatus = store.insuranceStatus();
  return { insuranceStatus };
}

export function insuranceStatusQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store['insurance-status']();
}
