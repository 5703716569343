@if (allFieldsLocked()) {
  <app-locked-quote-alert class="qui-form-layout__col-center"></app-locked-quote-alert>
}
@if (isAutoAdvanceEnabled()) {
  <p class="sr-only">Selecting an option will immediately submit the form and take you to the next page. You cannot navigate backwards and change your selection.</p>
}
<form [formGroup]="rentalStatusForm" (submit)="handleSubmit($event)" id="quote-form" class="qui-form-layout__col-center">
  <app-form-card title="Do you plan to rent out your property in the next 12 months?" icon="lucideHouse" class="qui-form-layout__col-center" formDisplayAddress="{{ formDisplayAddress }}">
    <fieldset [appReadonlyRadioGroup]="isFieldLocked('rentalStatus')" class="form-group__fieldset mt-6 flex flex-row gap-100 sm:mt-10 sm:gap-400" [appFocusOnlyRadioGroup]="isAutoAdvanceEnabled()">
      <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" data-testid="rental-status-yes" [class.radio-card--invalid]="showError">
        <input class="radio-card__control peer outline-none" type="radio" id="rental-status-yes" [value]="true" formControlName="rentalStatus" />
        <span for="rental-status-yes">Yes</span>
      </label>
      <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" data-testid="rental-status-no" [class.radio-card--invalid]="showError">
        <input class="radio-card__control peer outline-none" type="radio" id="rental-status-no" [value]="false" formControlName="rentalStatus" />
        <span for="rental-status-no">No</span>
      </label>
    </fieldset>

    <div aria-live="polite" aria-atomic="true">
      @if (showError) {
        <div class="form-help form-help--invalid flex items-center gap-100">
          <ng-icon name="lucideCircleAlert" aria-hidden="true" class="!overflow-visible"></ng-icon>
          <span class="error-message">Please select a rental option.</span>
        </div>
      }
    </div>
  </app-form-card>
</form>
