import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Signal, ViewChild, computed, effect, inject, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import '@kin/web-components/dist/external/kin-dialog/kin-dialog';
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoOptions } from '@maskito/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideCircleUserRound } from '@ng-icons/lucide';

import { FEATURE_FLAGS } from 'app/app-config/configurations/splitIO-config';
import { SocialProofComponent } from 'app/common/components/social-proof/social-proof.component';
import { Lockable, LockedQuoteAlertComponent, WithLockableFields } from 'app/create-quote/form-locking';

import { AboutYouQuoteFormInput } from './about-you.types';
import { TextInputComponent } from '../../../common/components/forms/text-input/text-input.component';
import { PHONE_NUMBERS } from '../../../common/constants/contact-constants';
import { VALIDATION_CONSTANTS } from '../../../common/constants/validation-constants';
import { PhonePipe } from '../../../common/pipes/phone-number/phone.pipe';
import { SplitIOService } from '../../../common/services/splitIO/splitIO.service';
import { utilToIsoDate } from '../../../common/utils/iso-date-type/to-iso-date';
import { getStateFullName } from '../../../common/utils/state-mapping/state-mapping';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { ElementVisibilityService } from '../../services/element-visibility/element-visibility.service';
import { ageValidator } from '../shared/age.validator';
import mask from '../shared/date-mask-options';
import { FormCardComponent } from '../shared/form-card.component';

@Component({
  selector: 'app-about-you',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [MaskitoDirective, FormCardComponent, ReactiveFormsModule, NgIconComponent, TextInputComponent, PhonePipe, RouterLink, LockedQuoteAlertComponent, SocialProofComponent],
  providers: [provideIcons({ lucideCircleUserRound, lucideCircleAlert })],
  templateUrl: './about-you.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }

      .disclaimer-small {
        font-size: 0.6875rem; // 11px, overrides the default font-sizes defined by kinetic
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/about-you',
})
export class QuoteFormAboutYouComponent extends BaseQuoteForm<AboutYouQuoteFormInput> implements AfterViewInit, Lockable {
  formDataIn!: AboutYouQuoteFormInput;
  private elementRef = inject(ElementRef);
  public elementVisibility = inject(ElementVisibilityService);
  private splitIOService = inject(SplitIOService);

  public csPhone = PHONE_NUMBERS.customerSupport;

  public hasTCPA = computed(() => this.elementVisibility.getVisibility()());

  readonly maskitoPhoneOptions: MaskitoOptions = {
    mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };

  readonly maskitoDateOptions: MaskitoOptions = mask;

  public formDefinition = {
    dob: new FormControl<string>('', {
      validators: [Validators.required, Validators.minLength(10), (control) => ageValidator(control, () => this.quoteDraftStore.address()?.state)],
      updateOn: 'blur',
    }),
    email: new FormControl<string>('', {
      validators: [Validators.required, Validators.email],
      updateOn: 'blur',
    }),
    firstName: new FormControl<string>('', {
      validators: [Validators.required],
      updateOn: 'blur',
    }),
    lastName: new FormControl<string>('', {
      validators: [Validators.required],
      updateOn: 'blur',
    }),
    phoneNumber: new FormControl<string>('', {
      validators: [Validators.required, Validators.pattern(VALIDATION_CONSTANTS.REGEX.phone)],
      updateOn: 'blur',
    }),
    hasOptedIntoSms: new FormControl<boolean>(true),
    hasGivenTcpaConsent: new FormControl<boolean>(true, {
      validators: [Validators.requiredTrue],
    }),
    hasGivenEigConsent: new FormControl<boolean>(true),
  };

  public aboutYouComponentForm = new FormGroup(this.formDefinition);

  public errorMessages = {
    firstName: {
      required: 'Your first name is required',
    },
    lastName: {
      required: 'Your last name is required',
    },
    dob: {
      required: 'Your date of birth is required.',
      minlength: 'Please enter a valid date in MM/DD/YYYY format.',
      invalidDateFormat: 'Please enter a valid date in MM/DD/YYYY format.',
      underage: 'Birthdate is outside the eligible range.',
      overMaxAge: 'Birthdate is outside the eligible range.',
    },
    phoneNumber: {
      required: 'Your phone number is required',
      pattern: 'Please enter a valid phone number',
    },
    email: {
      required: 'Your email address is required',
      pattern: 'Please enter a valid email address',
    },
    tcpaConsent: {
      requiredTrue: 'Please check the box to continue',
    },
    eigConsent: {
      requiredTrue: 'Please check the box to continue',
    },
  };

  public isFloridaResident = computed(() => this.quoteDraftStore.address()?.state === 'FL');
  public isCaliforniaResident = computed(() => this.quoteDraftStore.address()?.state === 'CA');

  public showTcpaError = signal(false);

  public isDialogOpen = false;

  public userState = computed(() => {
    const state = this.quoteDraftStore.address()?.state;
    return getStateFullName(state) ?? 'your area';
  });

  @ViewChild('tcpaVisibilityError') tcpaVisibilityError?: ElementRef;
  @ViewChild('tcpaConsentCheckbox') tcpaConsentCheckbox?: ElementRef;
  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  // Feature flag for EIG consent
  public showEigConsent = computed(() => {
    const treatment = this.splitIOService.getTreatment(FEATURE_FLAGS.QT_1034_TCPA_CONSENT_EIG);
    return treatment === 'show';
  });

  private changeRef = inject(ChangeDetectorRef);

  constructor() {
    super();
    effect((): void => {
      this.formDataIn = this.quoteDraftStore.aboutYouQuoteFormSelector();
      if (this.formDataIn) {
        this.aboutYouComponentForm.patchValue({
          dob: this.formDataIn.dob,
          email: this.formDataIn.email,
          firstName: this.formDataIn.firstName,
          lastName: this.formDataIn.lastName,
          phoneNumber: this.formDataIn.phoneNumber,
          hasOptedIntoSms: this.formDataIn.hasOptedIntoSms,
        });
      }

      const smsControl = this.aboutYouComponentForm.get('hasOptedIntoSms');
      if (smsControl) {
        if (this.isFieldLocked('hasOptedIntoSms')) {
          smsControl?.disable();
        } else {
          smsControl?.enable();
        }
      }

      // If all fields are locked, we can assume the user has already given consent
      if (this.allFieldsLocked()) {
        // TCPA consent is always required
        this.aboutYouComponentForm.controls.hasGivenTcpaConsent.setValue(true);
        this.aboutYouComponentForm.controls.hasGivenTcpaConsent.disable();
      }

      const eigControl = this.aboutYouComponentForm.get('hasGivenEigConsent');
      if (eigControl) {
        if (this.showEigConsent()) {
          eigControl.setValidators([Validators.requiredTrue]);
          if (this.allFieldsLocked()) {
            eigControl.setValue(true);
            eigControl.disable();
          }
        } else {
          eigControl.clearValidators();
          if (this.allFieldsLocked()) {
            eigControl.setValue(true);
          }
        }
        eigControl.updateValueAndValidity();
      }
    });

    // scroll to the tcpa error when the error is shown
    effect(() => {
      if (this.showTcpaError()) {
        // Let Angular finish rendering the error element before trying to scroll to it
        this.changeRef.detectChanges();
        this.scrollToTcpaError();
      }
    });
  }

  public getFormControls() {
    // Manually filter hasGivenTcpaConsent AND hasGivenEigConsent from the form controls since it is not saved in the store currently
    // Honestly, I just wanted to destructure but eslint chirps:
    // - First, about an unused variable so I rename it "_"
    // - Then it yells about  camelCase, PascalCase, UPPER_CASE  @typescript-eslint/naming-convention

    return Object.fromEntries(Object.entries(this.aboutYouComponentForm.controls).filter(([key]) => key !== 'hasGivenTcpaConsent' && key !== 'hasGivenEigConsent'));
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.aboutYouComponentForm.markAllAsTouched();

    if (this.aboutYouComponentForm.valid) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        // If the user hasn't viewed the consent details, show the error and scroll to it.
        // It is an explicit product requirement that the error persists, even after the user
        // has viewed the consent details. This is also why it is outside of the normal Angular
        // form validation
        if (!this.hasTCPA()) {
          this.showTcpaError.set(true);
          return;
        }
        super.saveFormData(this.getFormValues());
      }
    } else {
      this.scrollToAndFocusFirstInvalidFormControl();
    }
  }

  // Note: TCPA consent is not being saved in the store. Requiring TCPA consent via form validation and Fullstory tracking is sufficient for Legal requirements.
  private getFormValues(): Pick<QuoteDraftState, 'applicant' | 'hasOptedIntoSms'> {
    const { controls } = this.aboutYouComponentForm;
    const dob = controls.dob.value;
    const formattedDob = dob ? utilToIsoDate(dob) : undefined;
    const formValues = {
      applicant: {
        dob: formattedDob,
        email: controls.email.value ?? '',
        firstName: controls.firstName.value ?? '',
        lastName: controls.lastName.value ?? '',
        phoneNumber: controls.phoneNumber.value ?? '',
        languagePreference: navigator.language,
      },
      hasOptedIntoSms: this.aboutYouComponentForm.controls.hasOptedIntoSms.value ?? false,
    };

    return formValues;
  }

  public scrollToAndFocusFirstInvalidFormControl() {
    const form = document.getElementById('quote-form');
    const invalidControls = form?.getElementsByClassName('ng-invalid');
    if (invalidControls && invalidControls[0]) {
      const firstInvalidControl = invalidControls[0] as HTMLElement;
      // Scroll to the first invalid control's label if it exists, otherwise scroll to the control itself.
      const scrollElement = firstInvalidControl.parentElement?.tagName.toLowerCase() === 'label' ? firstInvalidControl.parentElement : firstInvalidControl;
      scrollElement.scrollIntoView({ behavior: 'smooth' });
      firstInvalidControl.focus({ preventScroll: true });
    }
  }

  protected close() {
    this.isDialogOpen = false;
  }

  protected open() {
    this.isDialogOpen = true;
  }

  /**
   * We setup a resize and intersection observer after init because
   * we need to know the final rendered footer height because it is sticky
   * If the footer is no longer sticky, this can be rethought.
   */
  ngAfterViewInit(): void {
    // Depending on whether the EIG consent is shown determines which element we want to observe for the
    // visibility validation.
    // Since EIG comes after TCPA, we observe the EIG element to ensure both are visible.
    // If EIG is not present, we want to observe just the TCPA element instead.
    const consentElementId = this.showEigConsent() ? 'eig-consent-label' : 'tcpa-consent-label';
    const consentElement = this.elementRef.nativeElement.querySelector(`#${consentElementId}`) as HTMLLabelElement;

    this.elementVisibility.observeElement(consentElement);
  }

  private scrollToTcpaError(): void {
    if (this.tcpaVisibilityError?.nativeElement) {
      this.tcpaVisibilityError.nativeElement.scrollIntoView({ behavior: 'smooth' });
      this.tcpaConsentCheckbox?.nativeElement?.focus();
    }
  }
}
