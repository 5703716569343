import { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';

import type { RoofYearQuoteFormInput } from './roof-year.types';
import type { QuoteDraftSelectorState } from '../../../../global-store/quote-draft';

export function roofYearQuoteFormSelector(store: QuoteDraftSelectorState): RoofYearQuoteFormInput {
  const roofYear = store.roofYear();
  const yearBuilt = store.yearBuilt();
  return { roofYear, yearBuilt };
}

export function roofYearQuoteFormValidationsSelector(store: QuoteFormOptionsSelectorState) {
  return store['roof-year']();
}
