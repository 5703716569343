interface CoverageFormat {
  percentage: string | null;
  value: string | null;
}

/**
 * Take in a string like "(10%) $1000" and return an object with
 * the percentage and value like
 * {
 *   percentage: "10%",
 *   value: "$1,000"
 * }
 *
 * @param input
 * @returns
 */
export function extractCoverageAmountAndPercentage(input: string | number | boolean): CoverageFormat {
  const defaultResult: CoverageFormat = {
    percentage: null,
    value: null,
  };

  if (!input || typeof input !== 'string') {
    return defaultResult;
  }

  /**
   * Regex for format: "(XX%) $Y,YYY"
   *
   * Example: "(10%) $1,000"
   *
   * Pattern explanation:
   * \(        - Match opening parenthesis
   * (\d+)     - Capture group 1: One or more digits (the percentage number)
   * %         - Match literal percent symbol
   * \)        - Match closing parenthesis
   * \s        - Match whitespace
   * (\$[0-9,]+) - Capture group 2: Dollar amount format:
   *               - \$     : Literal dollar sign
   *               - [0-9,] : Any digit or comma
   *               - +      : One or more of the above
   */
  const regexWithParentheses = /\((\d+)%\) (\$[0-9,]+)/;

  /**
   * Regex for format: "XX% $Y,YYY"
   * Example: "10% $1,000"
   *
   * Pattern explanation:
   * (\d+)     - Capture group 1: One or more digits (the percentage number)
   * %         - Match literal percent symbol
   * \s        - Match whitespace
   * (\$[0-9,]+) - Capture group 2: Dollar amount format:
   *               - \$     : Literal dollar sign
   *               - [0-9,] : Any digit or comma
   *               - +      : One or more of the above
   */
  const regexWithoutParentheses = /(\d+)% (\$[0-9,]+)/;

  // Try matching with parentheses first
  let match = input.match(regexWithParentheses);

  // If no match, try without parentheses
  if (!match) {
    match = input.match(regexWithoutParentheses);
  }

  // If we found a match in either format
  if (match) {
    return {
      percentage: `${match[1]}%`,
      value: match[2],
    };
  }

  // If the input only contains a dollar amount
  const dollarAmountOnly = /^\$[0-9,]+$/.exec(input);
  if (dollarAmountOnly) {
    return {
      percentage: null,
      value: dollarAmountOnly[0],
    };
  }

  return defaultResult;
}

export function isPercentageCoverage(value: unknown): boolean {
  return typeof value === 'string' && value.endsWith('%');
}
