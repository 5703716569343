import { inject } from '@angular/core';
import { withStorageSync } from '@angular-architects/ngrx-toolkit'; // docs: https://github.com/angular-architects/ngrx-toolkit
import { SESSION_STORAGE } from '@ng-web-apis/common';
import { signalStore, withState } from '@ngrx/signals';

import { withQuoteFormOptionsMethods } from './effects/quote-form-options.methods';
import { quoteFormOptionsInitialState } from './quote-form-options-initial-state';
import { withQuoteFormOptionsSelectors } from './selectors/quote-form-options.selectors';

export const QuoteFormOptionsStore = signalStore(
  { providedIn: 'root' },
  withState(quoteFormOptionsInitialState),
  withQuoteFormOptionsSelectors(),
  withQuoteFormOptionsMethods(),
  withStorageSync({
    key: 'options',
    autoSync: true,
    storage: () => inject(SESSION_STORAGE),
  }),
);
