import { ChangeDetectionStrategy, Component, OnInit, Signal, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideHouse } from '@ng-icons/lucide';

import { FocusOnlyRadioGroupDirective } from 'app/common/directives/focus-only-radio-group.directive';
import { ReadonlyRadioDirective } from 'app/common/directives/readonly-radio.directive';
import { FeatureFlagSignalsService } from 'app/common/services/splitIO/feature-flag.service';
import { Lockable, LockedQuoteAlertComponent, WithLockableFields } from 'app/create-quote/form-locking';

import { InsuranceExpirationInput } from './insurance-expiration.types';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { FormCardComponent } from '../shared/form-card.component';

@Component({
  selector: 'app-insurance-expiration',
  imports: [ReactiveFormsModule, FormCardComponent, NgIconComponent, ReadonlyRadioDirective, LockedQuoteAlertComponent, FocusOnlyRadioGroupDirective],
  providers: [provideIcons({ lucideHouse, lucideCircleAlert })],
  templateUrl: './insurance-expiration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/insurance_status_days',
  isAutoAdvanced: true,
})
export class QuoteFormInsuranceExpirationComponent extends BaseQuoteForm<InsuranceExpirationInput> implements OnInit, Lockable {
  private featureFlagSignalsService = inject(FeatureFlagSignalsService);
  public isAutoAdvanceEnabled = this.featureFlagSignalsService.isAutoAdvanceEnabled;

  public formDataIn: InsuranceExpirationInput = this.quoteDraftStore.insuranceExpirationQuoteFormSelector();

  public formDefinition = { isInsuranceExpiredMoreThan30Days: new FormControl<boolean | undefined>(undefined, Validators.required) };
  public formGrp = new FormGroup(this.formDefinition);

  public override autoAdvanceControlRef = this.formGrp.controls.isInsuranceExpiredMoreThan30Days;

  public ngOnInit(): void {
    const prefillValue = this.formDataIn?.isInsuranceExpiredMoreThan30Days;
    if (prefillValue !== undefined) {
      this.formGrp.controls.isInsuranceExpiredMoreThan30Days.setValue(prefillValue);
    }
  }

  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  public getFormControls() {
    return this.formGrp.controls;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.formGrp.markAllAsTouched();
    if (this.formGrp.valid && this.formGrp.controls.isInsuranceExpiredMoreThan30Days.value !== undefined) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        super.saveFormData({
          isInsuranceExpiredMoreThan30Days: this.formGrp.controls.isInsuranceExpiredMoreThan30Days.value!,
        });
      }
    }
  }

  public showFormRequiredError(): boolean {
    if (this.isAutoAdvanceEnabled()) {
      return false;
    }
    return this.formGrp.invalid && this.formGrp.touched;
  }
}
