import { NgKLoggerService } from '@kin/ngk-logger';

import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';

import { LegacyApiPagesPolicyTypeComponent, PolicyTypeConfig, PolicyTypeRadioButtonOption } from './policy-type.types';

export const HD3_STATES = ['MS', 'SC', 'VA', 'GA', 'AL', 'TN', 'AZ', 'TX'];

const policyTypeConfig: PolicyTypeConfig = {
  homeowners: {
    label: 'Homeowners',
    description: 'Applies if you own and live in a single-family or duplex home.',
    icon: 'home',
  },
  condominium: {
    label: 'Condo',
    description: 'Applies if an HOA or condo insures part of your home unit, or if you own and rent out a condo.',
    icon: 'condo',
  },
  mobile_home: {
    label: 'Manufactured home',
    description: 'Applies if you own and live in or own and rent out a manufactured or mobile home.',
    icon: 'manufactured_home',
  },
  landlord: {
    label: 'Landlord',
    description: 'Applies if you own and rent out a detached home or townhouse.',
    icon: 'landlord',
  },
};

export function getOptionsForLegacyPagesApiPolicyTypeComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): PolicyTypeRadioButtonOption[] {
  const policyTypeComponent = components.find((component) => component.attribute_name === 'policy_type');
  if (!policyTypeComponent) {
    logger.error({ message: 'could not find component for policy_type', context: 'getOptionsForLegacyPagesApiPolicyTypeComponent', priority: 'P3' });
    return [];
  }

  const options = (policyTypeComponent as LegacyApiPagesPolicyTypeComponent).select_options.map((option) => {
    return {
      label: policyTypeConfig[option.value]?.label || option.label,
      value: option.value,
      id: option.value.toString(),
      description: policyTypeConfig[option.value]?.description,
      icon: policyTypeConfig[option.value]?.icon || 'home', // Default to home icon
    };
  });

  return options;
}

export function isAddressInHD3(state: string): boolean {
  return HD3_STATES.includes(state);
}
