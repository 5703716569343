import { ChangeDetectionStrategy, Component, OnInit, Signal, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideArrowRightLeft, lucideCircleAlert, lucideCircleHelp, lucideClock, lucideGoal, lucideHousePlus, lucideKeyRound, lucidePhone, lucideShieldX } from '@ng-icons/lucide';

import * as cc from 'app/common/constants/contact-constants';
import { FocusOnlyRadioGroupDirective } from 'app/common/directives/focus-only-radio-group.directive';
import { ReadonlyRadioDirective } from 'app/common/directives/readonly-radio.directive';
import { PhonePipe } from 'app/common/pipes/phone-number/phone.pipe';
import { GoogleTagManagerService, SegmentEvent } from 'app/common/services/analytics/google-tagmanager/gtm.service';
import { FeatureFlagSignalsService } from 'app/common/services/splitIO/feature-flag.service';
import { Lockable, LockedQuoteAlertComponent, WithLockableFields } from 'app/create-quote/form-locking';
import { ContactDialogService } from 'app/create-quote/services/contact-dialog/contact-dialog.service';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';

import { InsuranceStatusQuoteFormInput, InsuranceStatusValue } from './insurance-status.types';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { FormCardComponent } from '../shared/form-card.component';

@Component({
  selector: 'app-insurance-status',
  imports: [ReactiveFormsModule, NgIconComponent, FormCardComponent, PhonePipe, ReadonlyRadioDirective, LockedQuoteAlertComponent, FocusOnlyRadioGroupDirective],
  templateUrl: './insurance-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [provideIcons({ lucideArrowRightLeft, lucideShieldX, lucideHousePlus, lucideKeyRound, lucideClock, lucideGoal, lucideCircleHelp, lucideCircleAlert, lucidePhone })],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/insurance_status',
  isAutoAdvanced: true,
})
export class QuoteFormInsuranceStatusComponent extends BaseQuoteForm<InsuranceStatusQuoteFormInput> implements OnInit, Lockable {
  private featureFlagSignalsService = inject(FeatureFlagSignalsService);
  public isAutoAdvanceEnabled = this.featureFlagSignalsService.isAutoAdvanceEnabled;

  private optionsStore = inject(QuoteFormOptionsStore);
  private gtmService = inject(GoogleTagManagerService);
  protected contactDialogService = inject(ContactDialogService);
  public formDataIn: InsuranceStatusQuoteFormInput = this.quoteDraftStore.insuranceStatusQuoteFormSelector();

  public insuranceStatusOptions = this.optionsStore.insuranceStatusQuoteFormOptionsSelector();

  public formDefinition = {
    insuranceStatus: new FormControl<InsuranceStatusValue | undefined>(undefined, [Validators.required]),
  };
  public insuranceStatusForm = new FormGroup(this.formDefinition);

  public salesPhoneNumber = cc.PHONE_NUMBERS.sales;
  public salesPhoneHoursFormatted = cc.PHONE_HOURS_FORMATTED.sales;

  public override autoAdvanceControlRef = this.insuranceStatusForm.controls.insuranceStatus;

  public ngOnInit() {
    if (this.formDataIn && this.isFieldLocked('insuranceStatus')) {
      this.insuranceStatusForm.controls.insuranceStatus.setValue(this.formDataIn.insuranceStatus);
    }
    this.gtmService.sendDataLayerEventWithQuoteData(SegmentEvent.INSURANCE_INFO_PAGE_VIEWED);
  }

  public getFormControls() {
    return this.insuranceStatusForm.controls;
  }

  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  private getFormValues(): Pick<QuoteDraftState, 'insuranceStatus'> {
    const { controls } = this.insuranceStatusForm;
    const selectedId = controls.insuranceStatus.value;
    const formValues: Pick<QuoteDraftState, 'insuranceStatus'> = {
      insuranceStatus: selectedId as InsuranceStatusValue,
    };

    return formValues;
  }

  public get showError(): boolean {
    if (this.isAutoAdvanceEnabled()) {
      return false;
    }

    const control = this.insuranceStatusForm.get('insuranceStatus');
    return !!control?.invalid && !!control?.touched;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.insuranceStatusForm.markAllAsTouched();
    if (this.insuranceStatusForm.valid) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        this.gtmService.sendDataLayerEventWithQuoteData(SegmentEvent.INSURANCE_INFO_PAGE_SUBMITTED, this.getFormValues());
        super.saveFormData(this.getFormValues());
      }
    }
  }
}
