import { NgKLoggerService } from '@kin/ngk-logger';

import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';

import { LegacyApiPagesResidencyComponent, ResidencyRadioButtonOption } from './residency.types';

export function getOptionsForLegacyPagesApiResidencyComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): ResidencyRadioButtonOption[] {
  const residencyComponent = components.find((component) => component.attribute_name === 'months_occupied');
  if (!residencyComponent) {
    logger.error({ message: 'could not find component for months_occupied', context: 'getOptionsForLegacyPagesApiResidencyComponent', priority: 'P3' });
    return [];
  }

  const options = (residencyComponent as LegacyApiPagesResidencyComponent).select_options.map((option) => {
    return {
      label: `${option.label}.`,
      value: option.value,
      id: option.value,
    };
  });

  return options;
}
