import { NgKLoggerService } from '@kin/ngk-logger';

import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';

import { FoundationTypeConfig, FoundationTypeRadioButtonOption, LegacyApiPagesFoundationTypeComponent } from './foundation.types';

const LEGACY_PAGES_API_FOUNDATION_TYPE_OPTIONS_VALUES = ['slab', 'crawlspace', 'elevated_piers_pilings', 'partially_finished_basement', 'elevated_with_enclosure'];

const foundationTypeConfig: FoundationTypeConfig = {
  slab: {
    label: 'Slab',
    description: 'Your home sits directly on the foundation, with no space like a crawlspace or basement underneath.',
    icon: 'slab.svg',
  },
  crawlspace: {
    label: 'Crawlspace',
    description: 'Your foundation has a small space, typically 3-4 feet high, between the ground and your home.',
    icon: 'crawl-space.svg',
  },
  elevated_piers_pilings: {
    label: 'Elevated Piers/Pilings',
    description: 'Your home is raised on piers or pilings, often with access to electrical and plumbing underneath.',
    icon: 'elevated-piers.svg',
  },
  partially_finished_basement: {
    label: 'Basement',
    description: "Choose this option if your home has a basement, whether it's finished or unfinished.",
    icon: 'basement.svg',
  },
  elevated_with_enclosure: {
    label: 'Elevated With Enclosure',
    description: "Your home is elevated, and there's an enclosed space underneath, like a garage.",
    icon: 'elevated-enclosure.svg',
  },
};

export function getOptionsForLegacyPagesApiFoundationTypeComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): FoundationTypeRadioButtonOption[] {
  const foundationTypeComponent = components.find((component) => component.attribute_name === 'foundation');
  if (!foundationTypeComponent) {
    logger.error({ message: 'could not find component for `foundation` type', context: 'getOptionsForLegacyPagesApiFoundationTypeComponent', priority: 'P3' });
    return [];
  }

  const options = (foundationTypeComponent as LegacyApiPagesFoundationTypeComponent).select_options.map((option) => {
    return {
      id: option.value,
      value: option.value,
      label: foundationTypeConfig[option.value]?.label || option.label,
      description: foundationTypeConfig[option.value]?.description,
      icon: foundationTypeConfig[option.value]?.icon || 'slab.svg', // default icon
    };
  });

  if (options.some((option) => !LEGACY_PAGES_API_FOUNDATION_TYPE_OPTIONS_VALUES.includes(option.value))) {
    logger.error({ message: 'unexpected value found in dot-com select_options for `foundation` type component', context: 'getOptionsForLegacyPagesApiFoundationTypeComponent', priority: 'P3' });
    return [];
  }

  return options;
}
