@if (isAutoAdvanceEnabled()) {
  <p class="sr-only">Selecting an option will immediately submit the form and take you to the next page. You cannot navigate backwards and change your selection.</p>
}
<form [formGroup]="roofShapeForm" (ngSubmit)="handleSubmit($event)" id="quote-form" class="qui-form-layout__col-center flex flex-col gap-400">
  @if (allFieldsLocked()) {
    <app-locked-quote-alert></app-locked-quote-alert>
  }
  <app-form-card
    title="What is the shape of your roof?"
    subcopy="Take a look at your roof and choose the option that best describes its design."
    icon="lucideHouse"
    formDisplayAddress="{{ formDisplayAddress }}"
  >
    <fieldset class="form-group__fieldset mt-400" [appReadonlyRadioGroup]="isFieldLocked('roofShape')" [appFocusOnlyRadioGroup]="isAutoAdvanceEnabled()">
      <legend class="sr-only">Select the shape of your roof</legend>
      <div class="grid gap-200 md:grid-cols-3">
        @for (roofShapeOption of roofShapeOptions; track roofShapeOption) {
          <label
            class="radio-card radio-card--block group focus-within:ring-2 focus-within:ring-primary"
            [class.radio-card--invalid]="errorMessage"
            [for]="roofShapeOption.id"
            [attr.data-testid]="'roof-shape-option-' + roofShapeOption.id"
          >
            <div class="radio-card__header">
              @if (roofShapeOption.value === 'hip') {
                <svg class="h-[1.5em] w-[2.2em]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 74 54">
                  <path fill="currentColor" fill-rule="evenodd" d="M64 19.68a.99.99 0 0 1 1 .98V50.02A.99.99 0 0 1 64 51a.99.99 0 0 1-1-.979V20.66a.99.99 0 0 1 1-.98Z" clip-rule="evenodd" />
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M25.128 5h23.237l19.28 15.89a.964.964 0 0 1 .12 1.379 1.015 1.015 0 0 1-1.41.118l-18.72-15.43H25.872L7.654 22.38a1.015 1.015 0 0 1-1.41-.1.964.964 0 0 1 .102-1.381L25.128 5Z"
                    clip-rule="evenodd"
                  />
                  <path fill="currentColor" fill-rule="evenodd" d="M10 19.68a.99.99 0 0 1 1 .98V50.02A.99.99 0 0 1 10 51a.99.99 0 0 1-1-.979V20.66a.99.99 0 0 1 1-.98Z" clip-rule="evenodd" />
                  <path fill="currentColor" fill-rule="evenodd" d="M6 50.021a.99.99 0 0 1 1-.978h60a.99.99 0 0 1 1 .978.99.99 0 0 1-1 .979H7a.99.99 0 0 1-1-.979Z" clip-rule="evenodd" />
                  <path fill="currentColor" fill-rule="evenodd" d="M30.918 33.383c0-1.081.895-1.957 2-1.957h8c1.105 0 2 .876 2 1.957v16.638h-2V33.383h-8v16.638h-2V33.383Z" clip-rule="evenodd" />
                </svg>
              } @else if (roofShapeOption.value === 'flat') {
                <svg class="h-[1.5em] w-[2.2em]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 74 54">
                  <path fill="currentColor" fill-rule="evenodd" d="M6 9.974A.987.987 0 0 1 7 9h60c.552 0 1 .436 1 .974a.987.987 0 0 1-1 .975H7c-.552 0-1-.437-1-.975Z" clip-rule="evenodd" />
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M64.918 9.974c.552 0 1 .437 1 .975v35.077a.987.987 0 0 1-1 .974c-.552 0-1-.436-1-.974V10.949a.987.987 0 0 1 1-.975ZM8.918 9.974c.552 0 1 .437 1 .975v35.077a.987.987 0 0 1-1 .974c-.552 0-1-.436-1-.974V10.949a.987.987 0 0 1 1-.975Z"
                    clip-rule="evenodd"
                  />
                  <path fill="currentColor" fill-rule="evenodd" d="M6 46.026a.987.987 0 0 1 1-.975h60c.552 0 1 .436 1 .975A.987.987 0 0 1 67 47H7c-.552 0-1-.436-1-.974Z" clip-rule="evenodd" />
                  <path fill="currentColor" fill-rule="evenodd" d="M30.918 29.462c0-1.077.895-1.95 2-1.95h8c1.105 0 2 .873 2 1.95v16.564h-2V29.46h-8v16.565h-2V29.46Z" clip-rule="evenodd" />
                </svg>
              } @else {
                <svg class="h-[1.5em] w-[2.2em]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 74 54">
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M36.069.204A1 1 0 0 1 37.27.198l30.326 22.561a1 1 0 0 1-1.194 1.605L36.68 2.251 7.606 24.358a1 1 0 0 1-1.21-1.592L36.069.204Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M64 21a1 1 0 0 1 1 1v31a1 1 0 0 1-2 0V22a1 1 0 0 1 1-1ZM10 21a1 1 0 0 1 1 1v31a1 1 0 0 1-2 0V22a1 1 0 0 1 1-1Z"
                    clip-rule="evenodd"
                  />
                  <path fill="currentColor" fill-rule="evenodd" d="M6 53a1 1 0 0 1 1-1h60a1 1 0 0 1 0 2H7a1 1 0 0 1-1-1Z" clip-rule="evenodd" />
                  <path fill="currentColor" fill-rule="evenodd" d="M31 36a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v17h-2V36h-8v17h-2V36Z" clip-rule="evenodd" />
                </svg>
              }

              <span class="radio-card__title">{{ roofShapeOption.label }} </span>
            </div>
            <span class="radio-card__description group-has-[:checked]:font-bold">{{ roofShapeOption.description }}</span>
            <input class="radio-card__control peer outline-none" type="radio" [value]="roofShapeOption.value" [id]="roofShapeOption.id" formControlName="roofShape" />
          </label>
        }
      </div>
    </fieldset>
    @if (errorMessage) {
      <div class="form-help form-help--invalid mt-300 flex items-center gap-100" data-testid="form-error-message">
        <ng-icon name="lucideCircleAlert" size="16" class="!overflow-visible" aria-hidden="true"></ng-icon>
        Please make a selection
      </div>
    }
  </app-form-card>
</form>
