import type { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';

import type { UnitNumberCheckQuoteFormInput } from './unit-number-check.types';
import type { QuoteDraftSelectorState } from '../../../global-store/quote-draft';

export function unitNumberCheckQuoteFormSelector(store: QuoteDraftSelectorState): UnitNumberCheckQuoteFormInput {
  const hasUnitNumber = store.hasUnitNumber();
  return { hasUnitNumber };
}

export function unitNumberCheckQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store['unit-number-check']();
}
