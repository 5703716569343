import { Type } from '@angular/core';
import { FormControl } from '@angular/forms';

import type { BaseQuoteForm } from './quote-form-base';
import { LegacyQuotePagesPath } from '../../common/services/legacy/quote-pages';
/**
 * **********************************************
 * QUOTE FORM DECORATOR
 * Decorator adds metadata to a QuoteForm components.
 * more on decorators: https://www.typescriptlang.org/docs/handbook/decorators.html#property-decorators
 * **********************************************
 */

// Discriminated union for the decorator configuration https://www.typescriptlang.org/docs/handbook/typescript-in-5-minutes-func.html#discriminated-unions
type AutoAdvancedConfig = {
  legacyShape?: LegacyQuotePagesPath;
  isAutoAdvanced: true;
};

type StandardConfig = {
  legacyShape?: LegacyQuotePagesPath;
  isAutoAdvanced?: false | undefined;
};

// Union type for the decorator configuration
type QuoteFormContextConfig = AutoAdvancedConfig | StandardConfig;

// Type for components that must have autoAdvanceControlRef
type WithAutoAdvanceControl = {
  autoAdvanceControlRef: FormControl;
};

/**
 * Runtime validation function to ensure components with isAutoAdvanced: true
 * have defined an autoAdvanceControlRef
 */
function validateAutoAdvancedComponent(componentInstance: BaseQuoteForm<unknown>, componentName: string): void {
  if (componentInstance.autoAdvanceControlRef === undefined) {
    throw new Error(`Component ${componentName} has isAutoAdvanced: true but does not define autoAdvanceControlRef`);
  }
}

// Overloaded decorator functions with discriminated union types
export function QuoteFormContextDecorator(config: AutoAdvancedConfig): <T extends Type<BaseQuoteForm<unknown> & WithAutoAdvanceControl>>(component: T) => T;
export function QuoteFormContextDecorator(config: StandardConfig): <T extends Type<BaseQuoteForm<unknown>>>(component: T) => T;
export function QuoteFormContextDecorator(config: QuoteFormContextConfig) {
  return <T extends Type<BaseQuoteForm<unknown>>>(component: T): T => {
    // Define static properties on the component
    Object.defineProperties(component, {
      formLegacyPath: {
        value: config.legacyShape,
        writable: false,
      },
      isAutoAdvanced: {
        value: config.isAutoAdvanced,
        writable: false,
      },
      // Add a static validation method that can be called by the orchestration component
      validateAutoAdvance: {
        value(instance: BaseQuoteForm<unknown>) {
          if (config.isAutoAdvanced === true) {
            validateAutoAdvancedComponent(instance, component.name);
          }
        },
        writable: false,
      },
    });

    return component;
  };
}
