<div class="qui-form-layout__col-center flex flex-col gap-400">
  @if (allFieldsLocked()) {
    <app-locked-quote-alert></app-locked-quote-alert>
  }

  <app-form-card title="Do you want to add a co-applicant?" icon="lucideGoal" formDisplayAddress="{{ formDisplayAddress }}">
    <ng-template #subcopyTemplate>
      <p class="m-b-300">
        Add a spouse, partner, or co-owner as a co-applicant to ensure they are covered. This provides shared protection, simplifies claims, and offers added convenience for all parties.
      </p>
      <p>
        If the co-applicant is not a person (like a Trust, LLC, etc.) please
        <button (click)="contactDialogService.open()" class="button button--link" aria-label="Call Kin about your quote at 855-717-0022" data-testid="call-us-button">call us</button> to add them to
        your policy.
      </p>
    </ng-template>

    <form [formGroup]="coApplicantInfoForm" class="qui-form-layout__col-center flex flex-col gap-400" (submit)="handleSubmit($event)" id="quote-form">
      <div class="grid grid-cols-1 gap-x-400 gap-y-400 sm:grid-cols-6 md:gap-y-600">
        <app-text-input
          class="sm:col-span-3"
          [control]="coApplicantInfoForm.controls.firstName"
          (blurred)="handleBlur()"
          label="Co-applicant first name"
          id="first-name"
          data-testid="co-applicant-first-name"
          [required]="true"
          [errorMessages]="errorMessages.firstName"
          helpText="Please provide their legal name"
          [readonly]="isFieldLocked('firstName')"
        ></app-text-input>

        <app-text-input
          class="sm:col-span-3"
          [control]="coApplicantInfoForm.controls.lastName"
          (blurred)="handleBlur()"
          label="Co-applicant last name"
          id="last-name"
          data-testid="co-applicant-last-name"
          [required]="true"
          [errorMessages]="errorMessages.lastName"
          helpText="Please provide their legal name"
          [readonly]="isFieldLocked('lastName')"
        ></app-text-input>

        <app-text-input
          class="sm:col-span-3"
          [control]="coApplicantInfoForm.controls.dob"
          (blurred)="handleBlur()"
          label="Co-applicant date of birth"
          [maskito]="maskitoDateOptions"
          inputmode="numeric"
          placeholder="MM/DD/YYYY"
          id="dob"
          data-testid="co-applicant-dob"
          autocomplete="birthday"
          type="text"
          [required]="true"
          [errorMessages]="errorMessages.dob"
          [readonly]="isFieldLocked('dob')"
        ></app-text-input>
      </div>
    </form>
  </app-form-card>
</div>
