/**
 * Global Store Service
 * - used to perform actions on multiple stores through a single point.
 */
import { Injectable, inject } from '@angular/core';
import { NgKLoggerService } from '@kin/ngk-logger';
import { LOCAL_STORAGE } from '@ng-web-apis/common';

import { QuoteDraftStore } from './quote-draft';
import { QuoteFormOptionsStore } from './quote-form-options';
import { UserStore } from './user/user.store';
import { LOCAL_STORAGE_DRAFT_TOKEN_KEY } from '../create-quote/services/entry/quote-draft-entry.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalStoreActionsService {
  private quoteDraftStore = inject(QuoteDraftStore);
  private optionsStore = inject(QuoteFormOptionsStore);
  private userStore = inject(UserStore);
  private localStorage = inject(LOCAL_STORAGE);
  private logger = inject(NgKLoggerService);

  public resetCreateQuote() {
    this.quoteDraftStore.reset();
    this.optionsStore.reset();
    this.userStore.endQuoting();
    // clear local storage when we reset the store
    this.localStorage.removeItem(LOCAL_STORAGE_DRAFT_TOKEN_KEY);
  }

  public newQuote() {
    this.quoteDraftStore.reset();
    this.optionsStore.reset();
    this.userStore.endQuoting();
  }

  public ejectUser(reason: string) {
    this.resetCreateQuote();
    this.logger.event({ metaData: { reason }, name: 'user-ejected' });
  }
}
