import { ChangeDetectionStrategy, Component, OnInit, Signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideHouse } from '@ng-icons/lucide';

import { Lockable, LockedQuoteAlertComponent, WithLockableFields } from 'app/create-quote/form-locking';

import { UnitNumberEntryQuoteFormInput } from './unit-number-entry.types';
import { TextInputComponent } from '../../../common/components/forms/text-input/text-input.component';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { FormCardComponent } from '../shared/form-card.component';

@Component({
  selector: 'app-unit-number-entry',
  imports: [ReactiveFormsModule, FormCardComponent, TextInputComponent, LockedQuoteAlertComponent],
  templateUrl: './unit-number-entry.component.html',
  providers: [provideIcons({ lucideHouse, lucideCircleAlert })],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/unit_number_entry',
})
export class QuoteFormUnitNumberEntryComponent extends BaseQuoteForm<UnitNumberEntryQuoteFormInput> implements OnInit, Lockable {
  public formDataIn: UnitNumberEntryQuoteFormInput = this.quoteDraftStore.unitNumberEntryQuoteFormSelector();

  public formDefinition = {
    unitNumber: new FormControl<string | undefined>(undefined, Validators.required),
  };

  public unitNumberEntryForm = new FormGroup(this.formDefinition);

  public showFormRequiredError(): boolean {
    const control = this.unitNumberEntryForm.get('unitNumber');
    return !!control?.touched && control?.value === undefined;
  }

  public ngOnInit() {
    if (this.formDataIn) {
      this.unitNumberEntryForm.controls.unitNumber.setValue(this.formDataIn.unitNumber);
    }
  }

  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  public getFormControls() {
    return this.unitNumberEntryForm.controls;
  }

  private getFormValues(): Pick<QuoteDraftState, 'unitNumber'> {
    const { controls } = this.unitNumberEntryForm;
    const formValues = {
      unitNumber: controls.unitNumber.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.unitNumberEntryForm.markAllAsTouched();
    if (this.unitNumberEntryForm.valid) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        super.saveFormData(this.getFormValues());
      }
    }
  }

  public errorMessages = {
    unitNumber: {
      required: 'Please enter your unit number.',
    },
  };
}
