import { Injectable, computed } from '@angular/core';

import { FEATURE_FLAGS } from 'app/app-config/configurations/splitIO-config';

import { SplitIOService } from './splitIO.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagSignalsService {
  constructor(private splitIOService: SplitIOService) {}

  readonly isAutoAdvanceEnabled = computed(() => this.splitIOService.getTreatment(FEATURE_FLAGS.QT_1116_auto_progress_forms) === 'on');
}
