import { ChangeDetectionStrategy, Component, OnInit, Signal, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideHouse } from '@ng-icons/lucide';

import { Lockable, LockedQuoteAlertComponent, WithLockableFields } from 'app/create-quote/form-locking';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';

import { RentalNumberQuoteFormInput, RentalNumberValue } from './rental-number.types';
import { ReadonlySelectDirective } from '../../../common/directives/readonly-select.directive';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { FormCardComponent } from '../shared/form-card.component';

@Component({
  selector: 'app-rental-number',
  imports: [ReactiveFormsModule, NgIconComponent, FormCardComponent, LockedQuoteAlertComponent, ReadonlySelectDirective],
  providers: [provideIcons({ lucideHouse, lucideCircleAlert })],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './rental-number.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/rental_status_number',
})
export class QuoteFormRentalNumberComponent extends BaseQuoteForm<RentalNumberQuoteFormInput> implements OnInit, Lockable {
  private optionsStore = inject(QuoteFormOptionsStore);

  public formDataIn: RentalNumberQuoteFormInput = this.quoteDraftStore.rentalNumberQuoteFormSelector();
  public rentalNumberOptions = this.optionsStore.rentalNumberQuoteFormOptionsSelector();

  public formDefinition = {
    rentalNumber: new FormControl<RentalNumberValue | undefined>(undefined, Validators.required),
  };
  public rentalNumberForm = new FormGroup(this.formDefinition);

  public ngOnInit() {
    if (this.formDataIn) {
      this.rentalNumberForm.controls.rentalNumber.setValue(this.formDataIn.rentalNumber);
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'rentalNumber'> {
    const { controls } = this.rentalNumberForm;
    const rentalNumberSelected = {
      rentalNumber: controls.rentalNumber.value ?? undefined,
    };

    return rentalNumberSelected;
  }

  public getFormControls() {
    return this.rentalNumberForm.controls;
  }

  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.rentalNumberForm.markAllAsTouched();
    if (this.rentalNumberForm.valid) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        super.saveFormData(this.getFormValues());
      }
    }
  }

  public showFormRequiredError(): boolean {
    const control = this.rentalNumberForm.get('rentalNumber');
    return control ? control.invalid && control.touched : false;
  }

  // All states except FL and LA use HD3 policies for (partial) rental properties. HD3 policies have a unique set of form options.
  public isFloridaOrLouisiana(): boolean {
    return this.quoteDraftStore.address()?.state === 'FL' || this.quoteDraftStore.address()?.state === 'LA';
  }
}
