import { ChangeDetectionStrategy, Component, OnInit, Signal, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideHouse } from '@ng-icons/lucide';

import { FocusOnlyRadioGroupDirective } from 'app/common/directives/focus-only-radio-group.directive';
import { FeatureFlagSignalsService } from 'app/common/services/splitIO/feature-flag.service';
import { Lockable, LockedQuoteAlertComponent, WithLockableFields } from 'app/create-quote/form-locking';

import { RentalStatusQuoteFormInput } from './rental-status.types';
import { ReadonlyRadioDirective } from '../../../common/directives/readonly-radio.directive';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { FormCardComponent } from '../shared/form-card.component';

@Component({
  selector: 'app-rental-status',
  imports: [ReactiveFormsModule, NgIconComponent, FormCardComponent, ReadonlyRadioDirective, LockedQuoteAlertComponent, FocusOnlyRadioGroupDirective],
  templateUrl: './rental-status.component.html',
  providers: [provideIcons({ lucideHouse, lucideCircleAlert })],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/rental_status',
  isAutoAdvanced: true,
})
export class QuoteFormRentalStatusComponent extends BaseQuoteForm<RentalStatusQuoteFormInput> implements OnInit, Lockable {
  private featureFlagSignalsService = inject(FeatureFlagSignalsService);
  public isAutoAdvanceEnabled = this.featureFlagSignalsService.isAutoAdvanceEnabled;

  public formDataIn: RentalStatusQuoteFormInput = this.quoteDraftStore.rentalStatusQuoteFormSelector();

  public formDefinition = {
    rentalStatus: new FormControl<boolean | undefined>(undefined, Validators.required),
  };
  public rentalStatusForm = new FormGroup(this.formDefinition);

  public override autoAdvanceControlRef = this.rentalStatusForm.controls.rentalStatus;

  public ngOnInit() {
    if (this.formDataIn && this.isFieldLocked('rentalStatus')) {
      this.rentalStatusForm.controls.rentalStatus.setValue(this.formDataIn.rentalStatus);
    }
  }

  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  getFormControls() {
    return this.rentalStatusForm.controls;
  }

  private getFormValues(): Pick<QuoteDraftState, 'rentalStatus'> {
    const { controls } = this.rentalStatusForm;
    const formValues = {
      rentalStatus: controls.rentalStatus.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.rentalStatusForm.markAllAsTouched();
    if (this.rentalStatusForm.valid) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        super.saveFormData(this.getFormValues());
      }
    }
  }

  public get showError(): boolean {
    if (this.isAutoAdvanceEnabled()) {
      return false;
    }
    const control = this.rentalStatusForm.get('rentalStatus');
    return !!control?.invalid && !!control?.touched;
  }
}
