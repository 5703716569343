import { computed } from '@angular/core';
import { signalStoreFeature, type, withComputed } from '@ngrx/signals';

import { aboutYouQuoteFormOptionsSelector } from 'app/create-quote/forms/about-you/about-you.selector';
import { hasCoApplicantQuoteFormOptionsSelector } from 'app/create-quote/forms/co-applicant/co-applicant.selector';
import { insuranceStatusQuoteFormOptionsSelector } from 'app/create-quote/forms/insurance-status/insurance-status.selector';
import { policyTypeQuoteFormOptionsSelector } from 'app/create-quote/forms/policy-type/policy-type.selector';
import { constructionTypeQuoteFormOptionsSelector } from 'app/create-quote/forms/property-details/construction-type/construction-type.selector';
import { exteriorWallQuoteFormOptionsSelector } from 'app/create-quote/forms/property-details/exterior-wall/exterior-wall.selector';
import { foundationTypeQuoteFormOptionsSelector } from 'app/create-quote/forms/property-details/foundation/foundation.selector';
import { propertyTypeQuoteFormOptionsSelector } from 'app/create-quote/forms/property-details/property-type/property-type.selector';
import { roofShapeQuoteFormOptionsSelector } from 'app/create-quote/forms/property-details/roof-shape/roof-shape.selector';
import { roofYearQuoteFormValidationsSelector } from 'app/create-quote/forms/property-details/roof-year/roof-year.selector';
import { yearBuiltQuoteFormOptionsSelector } from 'app/create-quote/forms/property-details/year-built/year-built.selector';
import { propertyInformationQuoteFormOptionsSelector } from 'app/create-quote/forms/property-information/property-information.selector';
import { rentalNumberQuoteFormOptionsSelector } from 'app/create-quote/forms/rental-number/rental-number.selector';
import { rentalStatusQuoteFormOptionsSelector } from 'app/create-quote/forms/rental-status/rental-status.selector';
import { unitNumberCheckQuoteFormOptionsSelector } from 'app/create-quote/forms/unit-number-check/unit-number-check.selector';
import { unitNumberEntryQuoteFormOptionsSelector } from 'app/create-quote/forms/unit-number-entry/unit-number-entry.selector';

import { QuoteFormOptionsState } from '../quote-form-options.model';

export function withQuoteFormOptionsSelectors() {
  return signalStoreFeature(
    { state: type<QuoteFormOptionsState>() },
    withComputed((store) => ({
      aboutYouQuoteFormOptionsSelector: computed(() => aboutYouQuoteFormOptionsSelector(store)),
      policyTypeQuoteFormOptionsSelector: computed(() => policyTypeQuoteFormOptionsSelector(store)),
      unitNumberCheckQuoteFormOptionsSelector: computed(() => unitNumberCheckQuoteFormOptionsSelector(store)),
      unitNumberEntryQuoteFormOptionsSelector: computed(() => unitNumberEntryQuoteFormOptionsSelector(store)),
      insuranceStatusQuoteFormOptionsSelector: computed(() => insuranceStatusQuoteFormOptionsSelector(store)),
      rentalStatusQuoteFormOptionsSelector: computed(() => rentalStatusQuoteFormOptionsSelector(store)),
      rentalNumberQuoteFormOptionsSelector: computed(() => rentalNumberQuoteFormOptionsSelector(store)),
      hasCoApplicantQuoteFormOptionsSelector: computed(() => hasCoApplicantQuoteFormOptionsSelector(store)),
      propertyTypeQuoteFormOptionsSelector: computed(() => propertyTypeQuoteFormOptionsSelector(store)),
      roofShapeQuoteFormOptionsSelector: computed(() => roofShapeQuoteFormOptionsSelector(store)),
      roofYearQuoteFormValidationsSelector: computed(() => roofYearQuoteFormValidationsSelector(store)),
      yearBuiltQuoteFormOptionsSelector: computed(() => yearBuiltQuoteFormOptionsSelector(store)),
      constructionTypeQuoteFormOptionsSelector: computed(() => constructionTypeQuoteFormOptionsSelector(store)),
      exteriorWallQuoteFormOptionsSelector: computed(() => exteriorWallQuoteFormOptionsSelector(store)),
      propertyInformationQuoteFormOptionsSelector: computed(() => propertyInformationQuoteFormOptionsSelector(store)),
      foundationTypeQuoteFormOptionsSelector: computed(() => foundationTypeQuoteFormOptionsSelector(store)),
    })),
  );
}
