import type { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';

import type { RentalStatusQuoteFormInput } from './rental-status.types';
import type { QuoteDraftSelectorState } from '../../../global-store/quote-draft';

export function rentalStatusQuoteFormSelector(store: QuoteDraftSelectorState): RentalStatusQuoteFormInput {
  const rentalStatus = store.rentalStatus();
  return { rentalStatus };
}

export function rentalStatusQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store['rental-status']();
}
