@if (isAutoAdvanceEnabled()) {
  <p class="sr-only">Selecting an option will immediately submit the form and take you to the next page. You cannot navigate backwards and change your selection.</p>
}
<form [formGroup]="unitNumberCheckForm" (submit)="handleSubmit($event)" id="quote-form" class="qui-form-layout__col-center flex w-full flex-col gap-400">
  @if (allFieldsLocked()) {
    <app-locked-quote-alert></app-locked-quote-alert>
  }
  <app-form-card title="Does your address have a unit number?" icon="lucideHouse" formDisplayAddress="{{ formDisplayAddress }}">
    <fieldset class="form-group__fieldset mt-400" [appReadonlyRadioGroup]="isFieldLocked('hasUnitNumber')" [appFocusOnlyRadioGroup]="isAutoAdvanceEnabled()">
      <legend class="sr-only">Does your address have a unit number?</legend>
      <div class="mt-6 flex flex-row gap-100 sm:mt-10 sm:gap-400">
        <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showFormRequiredError" [attr.data-testid]="'unit-number-check-yes'">
          <input class="radio-card__control peer outline-none" type="radio" id="unit-number-check-yes" [value]="true" formControlName="hasUnitNumber" />
          <span for="unit-number-check-yes">Yes</span>
        </label>
        <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showFormRequiredError" [attr.data-testid]="'unit-number-check-no'">
          <input class="radio-card__control peer outline-none" type="radio" id="unit-number-check-no" [value]="false" formControlName="hasUnitNumber" />
          <span for="unit-number-check-no">No</span>
        </label>
      </div>
    </fieldset>

    <div aria-live="polite" aria-atomic="true">
      @if (showFormRequiredError) {
        <div class="form-help form-help--invalid flex items-center gap-100">
          <ng-icon name="lucideCircleAlert" aria-hidden="true" class="!overflow-visible"></ng-icon>
          <span class="error-message">Please select a response.</span>
        </div>
      }
    </div>
  </app-form-card>
</form>
