<form [formGroup]="policyStartDateForm" class="qui-form-layout__col-center flex flex-col gap-400" (submit)="handleSubmit($event)" id="quote-form">
  @if (allFieldsLocked()) {
    <app-locked-quote-alert></app-locked-quote-alert>
  }
  <app-form-card
    [title]="'When would you like your insurance coverage to begin?'"
    subcopy="If you are unsure about your policy start date, you can change it later before finalizing your policy."
    icon="lucideGoal"
    formDisplayAddress="{{ formDisplayAddress }}"
  >
    <div class="mt-400 grid grid-cols-1 gap-x-400 gap-y-400 md:gap-y-600">
      <fieldset class="form-group__fieldset">
        <legend class="sr-only">Select your policy start date</legend>
        <app-text-input
          id="policy-start-date"
          type="date"
          [required]="true"
          [control]="policyStartDateForm.controls.policyStartDate"
          [readonly]="isFieldLocked('policyStartDate')"
          [min]="minDate"
          [max]="maxDate"
          helpText="Please choose a date within the next 60 days."
          [errorMessages]="errorMessages.policyStartDate"
          class="sm:col-span-1"
        ></app-text-input>
      </fieldset>
    </div>
  </app-form-card>
</form>
