import { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';

import { CoApplicantQuoteFormInput } from './co-applicant.types';
import type { QuoteDraftSelectorState } from '../../../global-store/quote-draft';

export function hasCoApplicantQuoteFormSelector(store: QuoteDraftSelectorState): CoApplicantQuoteFormInput {
  const hasCoApplicant = store.hasCoApplicant();
  return { hasCoApplicant };
}

export function hasCoApplicantQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store['co-applicant']();
}
