import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, Input, TemplateRef } from '@angular/core';
import { NgIconComponent } from '@ng-icons/core';

/**
 * FormCardComponent - A reusable card component
 *
 * @example - With subcopy slot. Used for more complex subcopy content.
 * <app-form-card title="What new devilry is this?" icon="lucideHouse">
 *   <ng-template #subcopyTemplate>
 *     Your custom subcopy content here
 *   </ng-template>
 *   Your form content here
 * </app-form-card>
 *
 * @example - With subcopy prop. Used for simple, one line subcopy.
 * <app-form-card title="What new devilry is this?" icon="lucideHouse" subcopy="Your subcopy here">
 *   Your form content here
 * </app-form-card>
 */

@Component({
  selector: 'app-form-card',
  imports: [NgIconComponent, NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="card p-0">
      @if (!!formDisplayAddress) {
        <div class="fw-bold ta-center rounded-t-lg bg-primary-300 p-300" data-testid="form-display-address">
          {{ formDisplayAddress }}
        </div>
      }
      <section class="p-[1.25rem] md:px-500 md:py-600">
        @if (title) {
          <h1 class="h2 flex flex-wrap items-center gap-100 md:flex-nowrap md:gap-300" data-testid="form-card-title">
            @if (icon) {
              <ng-icon [name]="icon" size="1.5em" class="flex-shrink-0 self-start" aria-hidden="true"></ng-icon>
            } @else if (customIcon) {
              <div class="flex-shrink-0 [&>svg]:h-[1.5em] [&>svg]:w-[1.5em]">
                <ng-content select="svg"></ng-content>
              </div>
            }
            <span class="flex-grow">{{ title }}</span>
          </h1>
        }
        @if (subcopyTemplate) {
          <div class="my-300 space-y-300">
            <ng-container *ngTemplateOutlet="subcopyTemplate"></ng-container>
          </div>
        } @else if (subcopy) {
          <p class="mb-300 mt-300">{{ subcopy }}</p>
        }
        <ng-content></ng-content>
      </section>
    </div>
  `,
})
export class FormCardComponent {
  /**
   * @description - The title of the card
   */
  @Input() title?: string = '';

  /**
   * @description - A sentence or two to provide more context to the card
   */
  @Input() subcopy?: string = '';

  /**
   * @description - The name of the icon to use. Make sure to add it to the providers in the parent component.
   */
  @Input() icon?: string = '';

  /**
   * @description - The string of the SVG for an icon. Can be used in cases where the icon is not in the icon library,
   * or if an icon with fill color is needed which is not supported currently by the Lucide Angular package.
   */
  @Input() iconSVG?: string = '';

  /**
   * @description - formDisplayAddress selected from the QuoteDraftStore, either displayAddress, clickLeadsAddress, or an empty string
   * @note - This is only used when the form card is part of a component that extends BaseQuoteForm
   */
  @Input() formDisplayAddress?: string = '';

  /**
   * @description - Template reference for custom subcopy content
   * @slot - Optional slot for custom subcopy content
   */
  @ContentChild('subcopyTemplate') subcopyTemplate?: TemplateRef<unknown>;

  /**
   * @description The custom icon of the form card
   * @property {ElementRef} icon - The custom icon of the form card
   * @slot icon - The custom icon of the form card
   */
  @ContentChild('customIcon') customIcon?: ElementRef;
}
