import type { QuoteFormOptionsSelectorState } from 'app/global-store/quote-form-options';

import type { UnitNumberEntryQuoteFormInput } from './unit-number-entry.types';
import type { QuoteDraftSelectorState } from '../../../global-store/quote-draft';

export function unitNumberEntryQuoteFormSelector(store: QuoteDraftSelectorState): UnitNumberEntryQuoteFormInput {
  const unitNumber = store.unitNumber();
  return { unitNumber };
}

export function unitNumberEntryQuoteFormOptionsSelector(store: QuoteFormOptionsSelectorState) {
  return store['unit-number-entry']();
}
