<div class="qui-form-layout__col-center flex w-full flex-col gap-400">
  @if (isAutoAdvanceEnabled()) {
    <p class="sr-only">Selecting an option will immediately submit the form and take you to the next page. You cannot navigate backwards and change your selection.</p>
  }
  <form [formGroup]="constructionTypeForm" (ngSubmit)="handleSubmit($event)" id="quote-form">
    @if (allFieldsLocked()) {
      <app-locked-quote-alert class="mb-400 block" [open]="true"></app-locked-quote-alert>
    }
    <app-form-card [title]="'What is the construction type of your home?'" icon="lucideHouse" formDisplayAddress="{{ formDisplayAddress }}">
      <div class="mt-600 grid grid-cols-1 gap-x-400 gap-y-400 md:gap-y-600">
        <fieldset class="form-group__fieldset" [appReadonlyRadioGroup]="isFieldLocked('constructionType')" [appFocusOnlyRadioGroup]="isAutoAdvanceEnabled()">
          <legend class="sr-only">Select the construction type</legend>
          <div class="grid gap-200 md:grid-cols-2">
            @for (constructionTypeOption of constructionTypeOptions; track constructionTypeOption) {
              <label
                class="radio-card radio-card--block group focus-within:ring-2 focus-within:ring-primary"
                [class.radio-card--invalid]="showError"
                [for]="constructionTypeOption.id"
                [attr.data-testid]="'construction-type-' + constructionTypeOption.id"
              >
                <div class="radio-card__header flex flex-col gap-100">
                  <div class="flex w-full items-start justify-start">
                    <img src="assets/images/{{ constructionTypeOption.image }}" alt="{{ constructionTypeOption.imageAlt }}" width="100" height="100" class="rounded-200 border !border-neutral-800" />
                    <div>
                      <p class="radio-card__title m-l-200 font-medium group-has-[:checked]:font-bold">{{ constructionTypeOption.label }}</p>
                      <p class="m-t-100 m-l-200">{{ constructionTypeOption.description }}</p>
                    </div>
                  </div>
                </div>
                <input class="radio-card__control peer outline-none" type="radio" [id]="constructionTypeOption.id" [value]="constructionTypeOption.id" formControlName="constructionType" />
              </label>
            }
          </div>
        </fieldset>
        @if (showError) {
          <div class="fs-body-md fw-medium error-message flex items-center gap-100 text-warning" role="alert" data-testid="form-error-message" aria-live="polite" aria-atomic="true">
            <ng-icon name="lucideCircleAlert" size="16" class="!overflow-visible" aria-hidden="true"></ng-icon>
            Please make a selection
          </div>
        }
      </div>
    </app-form-card>
  </form>
  <div class="border-100 rounded-200 bg-primary-300 px-500 py-300" data-testid="help-text">
    <div class="flex items-start gap-300">
      <ng-icon name="lucideCircleHelp" size="24" class="!overflow-visible [&>svg]:fill-primary" aria-hidden="true"></ng-icon>
      <div class="flex flex-col gap-300">
        <div>
          <p><strong>Not sure how to answer?</strong></p>
          <p class="hidden md:block">If you'd like a little extra help, our licensed agents are here for you.</p>
          <p class="md:hidden">One of our licensed agents will be happy to help.</p>
        </div>
        <div class="hidden md:block">
          <p class="font-bold">{{ salesPhoneNumber | phone }}</p>
          <p>{{ salesPhoneHoursFormatted }}</p>
        </div>
      </div>
    </div>

    <button (click)="contactDialogService.open()" data-testid="help-text-href" class="button button--sm button--secondary mt-300 w-full items-center justify-center gap-100 text-center md:hidden">
      <ng-icon name="lucidePhone" size="16" class="!overflow-visible" aria-hidden="true"></ng-icon>
      Talk to an agent
    </button>
  </div>
</div>
