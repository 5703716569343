/**
 * @file Provides the LegacyPagesApiService for interacting with the legacy Pages API.
 *
 * This service handles the communication with the legacy (v1) Pages API.
 * It integrates with the LegacyPagesPollingApiService for polling functionality.
 *
 * This should not be interacted with directly, the NgRx Effects should be communicating with this service.
 *
 * @module LegacyPagesApiService
 */
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { NgKLoggerService } from '@kin/ngk-logger';
import { of, switchMap, throwError } from 'rxjs';

import { LegacyApiPagesCir, LegacyApiPagesRequest, LegacyApiPagesResponse } from './models';
import { AppContext } from '../../../../app-config';
import { LegacyPagesPollingApiService } from '../quote-pages-polling/quote-pages-polling.service';
import { LegacyQuotePagesPath } from './models/paths.config';

@Injectable({
  providedIn: 'root',
})
export class LegacyPagesApiService {
  @AppContext() private _appContext!: AppContext;
  private httpClient = inject(HttpClient);
  private logger = inject(NgKLoggerService);
  private pollingService = inject(LegacyPagesPollingApiService);

  /**
   * Gets url based on app context
   */
  private get url() {
    return `${this._appContext.apiRoot.quoteV1}/pages`;
  }

  /**
   * SAVE PAGE DATA TO API
   * - Saves the about you information to the pages endpoint
   * - uses polling service after success and returns payload from the polling service
   *
   * @param {Partial<LegacyApiPagesCir>} formData - The form data to be saved.
   * @param {LegacyQuotePagesPath} path - The Legacy path to identify what the data shape is to the API
   * @returns {Observable<LegacyApiPagesResponse>} - An observable that emits the response from the API.
   */
  public save(formData: Partial<LegacyApiPagesCir>, path: LegacyQuotePagesPath) {
    if (!formData || !path) {
      const msg = `${!formData ? 'formData' : 'path'} is undefined`;
      this.logger.error({ message: msg, context: `LegacyPagesApiService::saveAboutYou`, priority: 'P4' });
      return throwError(() => new Error(msg));
    }

    // configure payload
    const payload = this.constructPayload(formData, path);

    // make API call
    return this.httpClient.post<LegacyApiPagesResponse>(this.url, payload).pipe(
      switchMap((response) => {
        // if we get a good response right away, no need to poll for it
        if (response.redirect_to) {
          return of(response);
        }

        if (response.page?.layout !== 'loading') {
          return of(response);
        }
        return this.pollingService.poll();
      }),
    );
  }

  /**
   * RETRIEVE NEXT PAGE FROM API WITHOUT MAKING A POST REQUEST FIRST
   * - Polls the PagesController for the next page in the flow.
   * - At the time of writing this, the only use case for making a GET request to the PagesController
   * without first making a POST request is immediately after submitting the user's address to the
   * AddressesController. Without doing this, we do not get back the expected pages from the PagesController.
   * @returns {Observable<LegacyApiPagesResponse>} - An observable that emits the response from the API.
   */
  public pollForNextPage() {
    return this.pollingService.poll().pipe(
      switchMap((response) => {
        if (response.redirect_to) {
          return of(response);
        }

        if (response.page?.layout !== 'loading') {
          return of(response);
        }
        return this.pollingService.poll();
      }),
    );
  }

  /**
   * NORMALIZE PAGES API PAYLOAD
   * creates the expected payload for the Pages API
   */
  private constructPayload(cir: Partial<LegacyApiPagesCir>, path: LegacyQuotePagesPath): LegacyApiPagesRequest {
    return {
      customerResponse: { ...cir },
      path,
    };
  }
}
