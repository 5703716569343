import { NgKLoggerService } from '@kin/ngk-logger';

import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';

import { LegacyApiPagesUnitNumberCheckComponent } from './unit-number-check.types';

const LEGACY_PAGES_API_UNIT_NUMBER_CHECK_OPTIONS_VALUES = [false, null];

export function getOptionsForLegacyPagesApiUnitNumberCheckComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): undefined {
  const unitNumberCheckComponent = components.find((component) => component.attribute_name === 'unit_number_check');
  if (!unitNumberCheckComponent) {
    logger.error({ message: 'could not find component for unit_number_check', context: 'getOptionsForLegacyPagesApiUnitNumberCheckComponent', priority: 'P3' });
    return;
  }

  const optionsValues = (unitNumberCheckComponent as unknown as LegacyApiPagesUnitNumberCheckComponent).select_options.map((option) => {
    return option.value;
  });

  if (optionsValues.some((option) => !LEGACY_PAGES_API_UNIT_NUMBER_CHECK_OPTIONS_VALUES.includes(option))) {
    logger.error({ message: 'unexpected value found in dot-com select_options for unit_number_check component', context: 'getOptionsForLegacyPagesApiUnitNumberCheckComponent', priority: 'P3' });
  }
}
