import { NgKLoggerService } from '@kin/ngk-logger';

import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';

import { InsuranceStatusConfig, InsuranceStatusRadioButtonOption, LegacyApiPagesInsuranceStatusComponent } from './insurance-status.types';

const insuranceStatusConfig: InsuranceStatusConfig = {
  currently_insured: {
    label: "I'm looking to switch insurance providers.",
    icon: 'lucideArrowRightLeft',
  },
  new_purchase_new_policy: {
    label: "I'm buying a home, but it's not my first time.",
    icon: 'lucideHousePlus',
  },
  no_insurance: {
    label: 'My previous coverage is expired, cancelled, or non-renewed.',
    icon: 'lucideShieldX',
  },
  new_purchase_new_policy_first_time: {
    label: "I'm buying a home for the first time.",
    icon: 'lucideKeyRound',
  },
};

export function getOptionsForLegacyPagesApiInsuranceStatusComponent(components: LegacyApiPagesComponent[], logger: NgKLoggerService): InsuranceStatusRadioButtonOption[] {
  const insuranceStatusComponent = components.find((component) => component.attribute_name === 'current_insurance_status');
  if (!insuranceStatusComponent) {
    logger.error({ message: 'could not find component for current_insurance_status', context: 'getOptionsForLegacyPagesApiInsuranceStatusComponent', priority: 'P3' });
    return [];
  }

  // `null` is a value coming back from dot-com for one of the options but cannot be used as an id and won't be used as a value,
  // so we need to provide a default e.g. `no_insurance`.
  const options = (insuranceStatusComponent as LegacyApiPagesInsuranceStatusComponent).select_options.map((option) => {
    const value = option.value || 'no_insurance';
    return {
      label: insuranceStatusConfig[value]?.label || option.label,
      value,
      id: value.toString(),
      icon: insuranceStatusConfig[value]?.icon || 'lucideClock', // Use Clock as default icon
    };
  });

  /**
   * New purchase policy gets split between two questions on the front end only.
   * The API only returns the value `new_purchase_new_policy` for the insurance status.
   * so we are manually adding a 2nd 'new_purchase_new_policy_first_time' option
   */
  options.splice(1, 0, {
    label: insuranceStatusConfig['new_purchase_new_policy_first_time'].label,
    value: 'new_purchase_new_policy_first_time',
    id: 'new_purchase_new_policy_first_time',
    icon: insuranceStatusConfig['new_purchase_new_policy_first_time'].icon,
  });

  return options;
}
