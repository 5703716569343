import { Directive, ElementRef, HostListener, Input, signal } from '@angular/core';

/**
 * Modifies radio group keyboard behavior to separate focus from selection.
 * When using arrow keys, only focus moves between options without selecting them.
 * Selection is still handled with Space
 * This differs from standard [W3C](https://www.w3.org/WAI/ARIA/apg/patterns/radiobutton/) radio
 * behavior where arrow keys both focus AND select.
 * We specifically need this because product requires that we auto submit the form as soon as the user clicks
 * a radio option and without this, keyboard only users would not be able to navigate the radio group.
 *
 * Features:
 * - Arrow Right/Up: Moves focus to next radio button
 * - Arrow Left/Down: Moves focus to previous radio button
 * - Circular navigation (wraps around from last to first and vice versa)
 * - Does not trigger selection on arrow key navigation
 * - Maintains standard Space behavior for selection
 *
 * Usage:
 * ```html
 * <fieldset appFocusOnlyRadioGroup>
 *   <input type="radio" ...>
 *   <input type="radio" ...>
 * </fieldset>
 * ```
 *
 * Or conditionally:
 * ```html
 * <fieldset [appFocusOnlyRadioGroup]="true">
 *   <input type="radio" ...>
 *   <input type="radio" ...>
 * </fieldset>
 * ```
 */
@Directive({
  selector: 'fieldset[appFocusOnlyRadioGroup]',
  standalone: true,
})
export class FocusOnlyRadioGroupDirective {
  private enabled = signal(true);

  @Input() set appFocusOnlyRadioGroup(value: boolean | '') {
    // Handle both property binding [appFocusOnlyRadioGroup]="true"
    // and attribute presence <fieldset appFocusOnlyRadioGroup>
    this.enabled.set(value === '' ? true : !!value);
  }

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // Skip if the directive is disabled
    if (!this.enabled()) return;

    // Get all radio buttons in the fieldset
    const radioButtons = Array.from(this.el.nativeElement.querySelectorAll('input[type="radio"]')) as HTMLInputElement[];
    if (!radioButtons.length) return;

    // Find which radio button currently has focus
    const currentIndex = radioButtons.findIndex((radio) => radio === document.activeElement);
    if (currentIndex === -1) return;

    let nextIndex: number;

    switch (event.key) {
      case 'ArrowRight':
      case 'ArrowUp':
        event.preventDefault();
        // Calculate next index with circular navigation
        nextIndex = (currentIndex + 1) % radioButtons.length;
        // Move focus without triggering selection
        radioButtons[nextIndex].focus({ preventScroll: true });
        break;

      case 'ArrowLeft':
      case 'ArrowDown':
        event.preventDefault();
        // Calculate previous index with circular navigation
        nextIndex = (currentIndex - 1 + radioButtons.length) % radioButtons.length;
        // Move focus without triggering selection
        radioButtons[nextIndex].focus({ preventScroll: true });
        break;

      default:
        // Let other keys pass through normally
        break;
    }
  }
}
