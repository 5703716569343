import { ChangeDetectionStrategy, Component, OnInit, Signal, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideCircleHelp, lucideHouse, lucidePhone } from '@ng-icons/lucide';

import * as cc from 'app/common/constants/contact-constants';
import { FocusOnlyRadioGroupDirective } from 'app/common/directives/focus-only-radio-group.directive';
import { ReadonlyRadioDirective } from 'app/common/directives/readonly-radio.directive';
import { PhonePipe } from 'app/common/pipes/phone-number/phone.pipe';
import { FeatureFlagSignalsService } from 'app/common/services/splitIO/feature-flag.service';
import { Lockable, WithLockableFields } from 'app/create-quote/form-locking';
import { ContactDialogService } from 'app/create-quote/services/contact-dialog/contact-dialog.service';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';

import { ConstructionTypeQuoteFormInput, ConstructionTypeValue } from './construction-type.types';
import { QuoteDraftState } from '../../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../../form-config/quote-form-decorator';
import { LockedQuoteAlertComponent } from '../../../form-locking/locked-quote-alert.component';
import { FormCardComponent } from '../../shared/form-card.component';

@Component({
  selector: 'app-construction-type',
  imports: [FormCardComponent, NgIconComponent, ReactiveFormsModule, PhonePipe, ReadonlyRadioDirective, LockedQuoteAlertComponent, FocusOnlyRadioGroupDirective],
  templateUrl: './construction-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [provideIcons({ lucideCircleAlert, lucideCircleHelp, lucideHouse, lucidePhone })],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/property-details/construction-type',
  isAutoAdvanced: true,
})
export class QuoteFormConstructionTypeComponent extends BaseQuoteForm<ConstructionTypeQuoteFormInput> implements OnInit, Lockable {
  private optionsStore = inject(QuoteFormOptionsStore);
  private featureFlagSignalsService = inject(FeatureFlagSignalsService);
  public isAutoAdvanceEnabled = this.featureFlagSignalsService.isAutoAdvanceEnabled;
  protected contactDialogService = inject(ContactDialogService);
  public formDataIn: ConstructionTypeQuoteFormInput = this.quoteDraftStore.constructionTypeQuoteFormSelector();

  public constructionTypeOptions = this.optionsStore.constructionTypeQuoteFormOptionsSelector();

  public formDefinition = {
    constructionType: new FormControl<ConstructionTypeValue | undefined>(undefined, Validators.required),
  };
  public constructionTypeForm = new FormGroup(this.formDefinition);

  public override autoAdvanceControlRef = this.constructionTypeForm.controls.constructionType;

  public salesPhoneNumber = cc.PHONE_NUMBERS.sales;
  public salesPhoneHoursFormatted = cc.PHONE_HOURS_FORMATTED.sales;

  public ngOnInit() {
    if (this.formDataIn && this.isFieldLocked('constructionType')) {
      this.constructionTypeForm.controls.constructionType.setValue(this.formDataIn.constructionType);
    }
  }

  public getFormControls() {
    return this.constructionTypeForm.controls;
  }

  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  private getFormValues(): Pick<QuoteDraftState, 'constructionType'> {
    const { controls } = this.constructionTypeForm;
    const formValues = {
      constructionType: controls.constructionType.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.constructionTypeForm.markAllAsTouched();
    if (this.constructionTypeForm.valid) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        super.saveFormData(this.getFormValues());
      }
    }
  }

  public get showError() {
    if (this.isAutoAdvanceEnabled()) {
      return false;
    }
    const control = this.constructionTypeForm.get('constructionType');
    return !!control?.invalid && !!control?.touched;
  }
}
