import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgKLoggerService } from '@kin/ngk-logger';
import { SESSION_STORAGE, WINDOW } from '@ng-web-apis/common';
import { tapResponse } from '@ngrx/operators';
import { WritableStateSource, patchState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { of, pipe } from 'rxjs';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { mergeSubmittedFields } from 'app/create-quote/form-locking/form-lock.util';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';

import { LegacyApiPagesResponse } from '../../../../common/services/legacy/quote-pages/models';
import { LegacyQuotePagesPath } from '../../../../common/services/legacy/quote-pages/models/paths.config';
import { LegacyPagesApiService } from '../../../../common/services/legacy/quote-pages/quote-pages.service';
import { mapLegacyPathToLegacyPayload } from '../../../../create-quote/form-config/map-legacy-path-to-legacy-payload';
import type { QuoteDraftSelectorState, QuoteDraftState } from '../../quote-draft.model';

/**
 * Save Form Action / Effect
 * Use to save a QuoteForm to the Quote Draft store and send to Pages API
 *
 * @todo we should extend this to include another to support a non-legacy path if it isn't defined as opposed to throwing an error
 */

export type SaveFormAction = {
  formData: Partial<QuoteDraftState>;
  legacyPath?: LegacyQuotePagesPath;
  fieldNames?: string[];
};

// pulls out the  field names from the form data
// recursively if there are nested objects
// ignores any values that are undefined. in multiform instances like year-built, the form options
//  are variable but we still POST undefined for questions that don't appear on the page
function extractFieldNames(data: Partial<QuoteDraftState>): string[] {
  return Object.entries(data).flatMap(([key, value]) => {
    if (value === undefined) return [];
    return typeof value === 'object' && value !== null ? extractFieldNames(value as Partial<QuoteDraftState>) : [key];
  });
}

export function saveForm(store: WritableStateSource<QuoteDraftState>, pagesApiService: LegacyPagesApiService, router: Router, logger: NgKLoggerService) {
  const optionsStore = inject(QuoteFormOptionsStore);
  const window = inject(WINDOW);
  const sessionStorage = inject(SESSION_STORAGE);

  const handleSuccess = (res: LegacyApiPagesResponse, saveFormAction: SaveFormAction) => {
    // Handle user exists flag
    if (res.page?.user_exists === true) {
      sessionStorage.setItem('user_exists', 'true');
    }

    // Handle form options
    if (res.page) {
      const formOptions = optionsStore.getLegacyOptionsForForm(res.page.path, res.page.components, logger);
      optionsStore.updateOptionsForFormByLegacyPath(res.page.path, formOptions, logger);
    }

    // Handle navigation
    // `redirect_to` takes user out of "questions" flow and to either:
    // - Terminal page, e.g. underwriting failure page.
    // - Compare Page then Review Page.
    // - Rails pages to finish the quote.
    // - Resume Page. The "/resume" page `redirect_to` link is returned as a full URL.
    if (res.redirect_to) {
      if (res.redirect_to.includes('http')) {
        window.location.href = res.redirect_to;
      } else {
        router.navigate([`${res.redirect_to}`]);
      }
    } else if (res.page) {
      router.navigate(['quote/legacy', res.page.path]);
    }

    // Mark submitted fields
    // Extract form key from legacy path (e.g. 'about-you' from '/quote/about-you')
    const formKey = saveFormAction.legacyPath?.replace('/quote/', '') ?? '';

    // Only proceed if we have form data, a form key, and fields to mark as submitted
    if (saveFormAction.formData && formKey && saveFormAction.fieldNames?.length) {
      patchState(store, (state) => ({
        submittedFields: mergeSubmittedFields(state.submittedFields, formKey, saveFormAction.fieldNames!),
      }));
    }

    return patchState(store, { storeStatus: 'success' });
  };

  return rxMethod<SaveFormAction>(
    pipe(
      distinctUntilChanged(),
      tap(() => patchState(store, { storeStatus: 'loading' })),
      switchMap((saveFormAction) => {
        // save form data to the store
        patchState(store, saveFormAction.formData);

        if (!saveFormAction.legacyPath) {
          logger.error({
            message: `unable to generate pages api request payload from legacyPath: ${saveFormAction.legacyPath}`,
            context: 'saveForm Effect/Action',
            priority: 'P3',
          });
          return of(
            patchState(store, {
              storeStatus: { code: 0, msg: 'Unexpected Error' },
            }),
          );
        }

        // Extract field names from form data
        const fieldNames = extractFieldNames(saveFormAction.formData);
        saveFormAction = { ...saveFormAction, fieldNames };

        // get the correct payload
        const payload = mapLegacyPathToLegacyPayload(store as unknown as QuoteDraftSelectorState, saveFormAction.legacyPath!);

        if (!payload) {
          logger.error({
            message: `Unable to generate payload for path: ${saveFormAction.legacyPath}`,
            context: 'saveForm Effect/Action',
            priority: 'P3',
          });
          return of(
            patchState(store, {
              storeStatus: { code: 0, msg: 'Unexpected Error' },
            }),
          );
        }

        // call API to create the customer input response id
        return pagesApiService.save(payload, saveFormAction.legacyPath!).pipe(
          tapResponse({
            next: (res) => {
              if (!res.page && !res.redirect_to) {
                logger.error({
                  message: 'Pages API response did not include a page',
                  context: 'saveForm Effect/Action::pagesApiService',
                  priority: 'P3',
                });
                return patchState(store, {
                  storeStatus: { code: 500, msg: 'Unexpected Error' },
                });
              }
              return handleSuccess(res, saveFormAction);
            },
            error: (res: HttpErrorResponse) => {
              const msg = res.message || 'Unexpected Error';
              logger.error({
                message: `Pages API failure ${msg}`,
                context: 'saveForm Effect/Action::pagesApiService',
                priority: 'P3',
              });
              return patchState(store, {
                storeStatus: { code: res.status ?? 500, msg },
              });
            },
          }),
        );
      }),
    ),
  );
}
