<div class="qui-form-layout__col-center">
  @if (allFieldsLocked()) {
    <app-locked-quote-alert class="mb-400 block"></app-locked-quote-alert>
  }
  <app-form-card title="We have a few more questions about your property." icon="lucideHouse" formDisplayAddress="{{ formDisplayAddress }}">
    <form [formGroup]="yearBuiltForm" (ngSubmit)="handleSubmit($event)" id="quote-form" class="mt-600">
      <div class="flex flex-col gap-600">
        @if (hasOption('year_built')) {
          <app-text-input
            [control]="yearBuiltForm.controls.yearBuilt"
            label="What year was this home built?"
            id="year-built"
            type="text"
            inputmode="numeric"
            [maskito]="fourDigitInputMask"
            [readonly]="isFieldLocked('yearBuilt')"
            maxlength="4"
            data-testid="year-built"
            pattern="\d{4}"
            min="1"
            [required]="true"
            [errorMessages]="errorMessages.yearBuilt"
          ></app-text-input>
        }

        @if (hasOption('sq_ft')) {
          <app-text-input
            class="sm:col-span-3"
            [readonly]="isFieldLocked('sqFt')"
            min="1"
            [control]="yearBuiltForm.controls.sqFt"
            label="What is the number of livable square feet?"
            data-testid="sq-ft"
            id="sq-ft"
            type="number"
            [required]="true"
            [errorMessages]="errorMessages.sqFt"
          ></app-text-input>
        }

        @if (hasOption('stories')) {
          <app-text-input
            class="sm:col-span-3"
            [readonly]="isFieldLocked('stories')"
            data-testid="stories"
            [control]="yearBuiltForm.controls.stories"
            label="How many stories does your property have?"
            id="stories"
            type="number"
            min="0"
            [required]="true"
            [errorMessages]="errorMessages.stories"
          ></app-text-input>
        }

        @if (hasOption('pool')) {
          <fieldset class="form-group__fieldset" [appReadonlyRadioGroup]="isFieldLocked('pool')">
            <legend [class]="'input__label ' + (yearBuiltForm.controls.pool.touched ? 'text-neutral-900' : 'text-neutral-700')" [class.text-warning]="showError(yearBuiltForm.controls.pool)">
              Do you have a swimming pool?
            </legend>

            <div class="mt-3 flex flex-row gap-100 sm:gap-400">
              <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" data-testid="pool-yes" [class.radio-card--invalid]="showError(yearBuiltForm.controls.pool)">
                <input class="radio-card__control peer outline-none" type="radio" id="pool-yes" [value]="true" formControlName="pool" />
                <span for="pool-yes">Yes</span>
              </label>
              <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" data-testid="pool-no" [class.radio-card--invalid]="showError(yearBuiltForm.controls.pool)">
                <input class="radio-card__control peer outline-none" type="radio" id="pool-no" [value]="false" formControlName="pool" />
                <span for="pool-no">No</span>
              </label>
            </div>

            <div id="'pool-message'" [class]="'form-help ' + (yearBuiltForm.controls.pool.touched ? 'text-neutral-900' : 'text-neutral-700')" aria-live="polite" aria-atomic="true">
              @if (showError(yearBuiltForm.controls.pool)) {
                <div class="form-help--invalid flex items-center gap-100" id="pool-error">
                  <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>
                  This field is required
                </div>
              }
            </div>
          </fieldset>
        }
      </div>
    </form>
  </app-form-card>
</div>
