import { format, parse, parseISO } from 'date-fns';

import { TDateISODate } from './iso-date.type';

/**
 * @uiDate = MM/DD/YYYY
 * @returns to YYYY-MM-DD
 *
 * @note - consider timezones here when we get into editing this date for users.
 */
export function utilToIsoDate(date: string | undefined): TDateISODate | undefined {
  if (date) {
    try {
      const formatString = detectDateFormat(date);
      const parsedDate = parse(date, formatString, new Date());
      return format(parsedDate, 'yyyy-MM-dd') as TDateISODate;
    } catch {
      return undefined;
    }
  }
  return undefined;
}

/**
 * Checks if a date string is in ISO format (YYYY-MM-DD).
 * @param date - The date string to check.
 * @returns true if the date string is in ISO format, false otherwise.
 */
export function isIsoDate(date: string | undefined): boolean {
  if (date) {
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return isoDateRegex.test(date);
  }
  return false;
}

/**
 * @isoDate = YYYY-MM-DD
 * @returns to MM/DD/YYYY
 */
export function utilToUiDate(isoDate: TDateISODate | undefined): string | undefined {
  if (isoDate) {
    try {
      return format(parseISO(isoDate), 'MM/dd/yyyy');
    } catch {
      return undefined;
    }
  }
  return undefined;
}

/**
 * Detects the format of the date string.
 * @param date - The date string to detect the format of.
 * @returns The detected format.
 */
function detectDateFormat(date: string): string {
  if (date.includes('/')) {
    const parts = date.split('/');
    if (parts[0].length === 4) {
      return 'yyyy/MM/dd';
    }
    return 'MM/dd/yyyy';
  }
  throw new Error('Unsupported date format');
}
