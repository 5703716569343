/**
 * Address Resume Mapping
 *
 * This file is EXTREMELY important.  We interpret what the API is giving to us and translating it into the frontend.
 * Risk: mapping a value wrong could result in overwriting data that we don't want to change.
 *
 * This file should always have 100% test coverage and any changes be well tested
 *
 * Related to `map-legacy-answers-to-quote-draft-store.ts`
 * Used by the `sync.service.ts`
 */
import { translateApiNullToUndefined } from './utilities';
import { QuoteDraftState } from '../../../../global-store/quote-draft';
import { LegacyApiUsersResponse } from '../users/users.service';

export type QuoteDraftStateLegacyUserApiMapping = Pick<QuoteDraftState, 'address'> | undefined;

export function mapLegacyUsersToQuoteDraftStore(payload: Partial<LegacyApiUsersResponse>): QuoteDraftStateLegacyUserApiMapping {
  if (payload && Object.keys(payload).length > 0) {
    return {
      address: {
        state: translateApiNullToUndefined(payload.state)!,
        street: translateApiNullToUndefined(payload.address)!,
        city: translateApiNullToUndefined(payload.city)!,
        unit: translateApiNullToUndefined(payload.unit_number)!,
        zipcode: translateApiNullToUndefined(payload.zip)!,
      },
    };
  }
  return undefined;
}
