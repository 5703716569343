import { inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgKLoggerService } from '@kin/ngk-logger';

import { FormLockService } from 'app/create-quote/form-locking';
import { GlobalStoreActionsService } from 'app/global-store/global-store-actions.service';

import type { LegacyQuotePagesPath } from '../../common/services/legacy/quote-pages';
import { QuoteDraftStore } from '../../global-store/quote-draft';
import type { QuoteDraftState } from '../../global-store/quote-draft/quote-draft.model';
import { QuoteFormSaveService } from '../services/save';

/**
 * **********************************************
 * BASE QUOTE FORM
 * Represents the base class for all quote forms.
 * @template FormData - The type of form data needed for the quote form.
 * **********************************************
 */
export abstract class BaseQuoteForm<QuoteFormInput> {
  // Injected Services

  readonly formLockService = inject(FormLockService);
  readonly quoteDraftStore = inject(QuoteDraftStore);
  readonly saveService = inject(QuoteFormSaveService);
  public globalActions = inject(GlobalStoreActionsService);
  public logger = inject(NgKLoggerService);
  public router = inject(Router);

  /**
   * Expose control to the Orchestration component
   * it needs to listen for events on this control to move the user forward
   * and control name needs to be the same for every form
   *
   * should be reference to the control the form is using, not the control itself
   *
   * having the base class define this provides consistency for orchestration
   * and flexibility to the form author
   *
   * IMPORTANT: If your component uses @QuoteFormContextDecorator with isAutoAdvanced: true,
   * you MUST override this property with a non-undefined FormControl reference.
   * The TypeScript compiler will enforce this constraint at compile time.
   */
  public autoAdvanceControlRef?: FormControl | undefined = undefined;

  // tells the service what the shape of the data is we want to save
  static formLegacyPath?: LegacyQuotePagesPath;

  // form data in
  abstract formDataIn?: QuoteFormInput;

  protected formKey = (this.constructor as typeof BaseQuoteForm).formLegacyPath?.replace('/quote/', '') ?? '';

  protected markFieldsAsSubmitted(fields: string[], formKey?: string): void {
    this.formLockService.markFieldsAsSubmitted(fields, formKey ?? this.formKey);
  }

  public isFieldSubmitted(fieldName: string): boolean {
    return this.formLockService.isFieldSubmitted(fieldName, this.formKey);
  }

  /**
   * requiring handleSubmit to be defined
   * we need to have this exposed so we can pragmatically call it from the orchestration component.
   * we can't call the saveFormData because the handleSubmit typically handles the data mapping to pump into the SaveFormData
   * so it needs called first
   */
  abstract handleSubmit(e: Event): void;

  // method that saves the form data to the save service, and then the store.
  public saveFormData(formData: Partial<QuoteDraftState>) {
    // pull in the form config details - references the constructor to ensure we get the instance value, as opposed to the abstract class value
    const formShape = (this.constructor as typeof BaseQuoteForm).formLegacyPath;
    // save form data to the service
    this.saveService.saveFormData(formData, formShape);
  }

  // global form data
  public get formDisplayAddress() {
    return this.quoteDraftStore.displayAddress() || this.quoteDraftStore.clickLeadsAddress() || '';
  }

  /**
   * @todo - this needs moved to the orchestration component
   * It is logic around managing form presentation
   * Use the decorators or services to event this logic out
   *
   * Composition over Inheritance
   */
  protected navigateForward(): void {
    const currentPath = window.location.pathname; // Store the current path

    // Try moving forward in history
    window.history.forward();

    // As a fallback, if the user navigates forward and doesn't have forward history, we eject so they can resume.
    // This one possible scenario is if the user manually enters and submits a form URL in the middle of a quote
    // Listen for changes in the path
    // forward doesn't return a promise so we need to wait for the timeout to see if the location changed
    const timeout = setTimeout(() => {
      // If the location didn't change, navigate to the review page
      if (window.location.pathname === currentPath) {
        this.logger.error({
          context: 'QuoteFormBase::navigateForward',
          message: `User tried to navigate forward but no forward history was detected`,
          priority: 'P3',
        });
        this.globalActions.ejectUser('no forward history');
        this.router.navigate(['/quote/start']);
      }
    }, 500);

    // Clear timeout if navigation is detected before timeout
    window.addEventListener(
      'popstate',
      () => {
        clearTimeout(timeout);
      },
      { once: true },
    );
  }
}
