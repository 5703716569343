import { Injectable, inject } from '@angular/core';
import { NgKLoggerService } from '@kin/ngk-logger';

import { Endorsement } from 'app/common/services/legacy/policies/policies.model';
import { LegacyApiCoverage } from 'app/common/services/legacy/policies/policies.service';

@Injectable({
  providedIn: 'root',
})
export class RspsMapperService {
  private logger = inject(NgKLoggerService);

  map(coverage: LegacyApiCoverage): Endorsement | null {
    try {
      const id = coverage.component?.attribute_name;
      if (!id) {
        this.logger.error({
          message: 'RSPS parsing failure: Missing attribute_name',
          context: 'RspsMapperService::map',
          priority: 'P3',
        });
        return null;
      }

      if (typeof coverage.value !== 'boolean') {
        this.logger.error({
          message: `Unexpected value response. Expected a boolean but got ${typeof coverage.value} instead`,
          context: 'RspsMapperService::map',
          priority: 'P3',
        });
        return null;
      }
      const { value } = coverage;
      const isEditable = typeof coverage.editable === 'boolean' ? coverage.editable : false;

      return {
        id,
        value,
        isEditable,
      };
    } catch {
      this.logger.error({
        message: 'RSPS parsing failure: Unexpected Error',
        context: 'RspsMapperService::map',
        priority: 'P3',
      });
      return null;
    }
  }
}
