import { Injectable, Signal, signal } from '@angular/core';

/**
 * Service to determine if an element is fully visible in the viewport.
 * Uses IntersectionObserver to efficiently monitor element visibility.
 */
@Injectable({
  providedIn: 'root',
})
export class ElementVisibilityService {
  private isVisible = signal(false);
  private intersectionObserver: IntersectionObserver | null = null;
  private resizeObserver: ResizeObserver | null = null;

  observeElement(element: HTMLElement) {
    this.cleanup();

    this.setupIntersectionObserver(element);

    // Set up resize observer to recalculate visibility on window resize
    this.resizeObserver = new ResizeObserver(() => {
      this.setupIntersectionObserver(element);
    });
    this.resizeObserver.observe(document.documentElement);
  }

  private setupIntersectionObserver(element: HTMLElement) {
    this.intersectionObserver?.disconnect();

    this.intersectionObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio === 1) {
          this.isVisible.set(true);
          this.intersectionObserver?.disconnect();
        } else {
          this.isVisible.set(false);
        }
      },
      {
        threshold: 1,
      },
    );

    this.intersectionObserver.observe(element);
  }

  getVisibility(): Signal<boolean> {
    return this.isVisible.asReadonly();
  }

  private cleanup() {
    this.intersectionObserver?.disconnect();
    this.resizeObserver?.disconnect();
    this.isVisible.set(false);
  }

  reset() {
    this.cleanup();
  }
}
