import { NgKLoggerService } from '@kin/ngk-logger';

import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';

const LEGACY_PAGES_API_INSURANCE_EXPIRATION_COMPONENT_ATTRIBUTE_NAMES = ['current_insurance_status'];

export function getComponentsForLegacyPagesApiInsuranceExpiration(components: LegacyApiPagesComponent[], logger: NgKLoggerService): undefined {
  const insuranceExpirationComponents = components.filter((component) => LEGACY_PAGES_API_INSURANCE_EXPIRATION_COMPONENT_ATTRIBUTE_NAMES.includes(component.attribute_name));
  if (insuranceExpirationComponents.length === 0 || insuranceExpirationComponents.length !== LEGACY_PAGES_API_INSURANCE_EXPIRATION_COMPONENT_ATTRIBUTE_NAMES.length) {
    logger.error({ message: 'could not find components for insurance expiration page', context: 'getComponentsForLegacyPagesApiInsuranceExpiration', priority: 'P3' });
  }
}
