import { NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert } from '@ng-icons/lucide';

/**
 * @description A resusable text input component.
 * This component is responsible for:
 * * Displaying the input and label
 * * Managing correct aria attributes
 * * Displaying the error messages and help text
 */
@Component({
  selector: 'app-text-input',
  imports: [ReactiveFormsModule, NgIconComponent, NgTemplateOutlet],
  providers: [provideIcons({ lucideCircleAlert })],
  templateUrl: './text-input.component.html',
  styles: [
    `
      // Kinetic fix. Can remove after https://github.com/kin/kinetic/issues/478 is fixed
      .input__control:focus-within:not(:disabled) {
        box-shadow: 0 0 0 3px var(--color-primary);
        outline: 1px solid var(--color-neutral-700);
      }
    `,
  ],
})
export class TextInputComponent {
  /**
   * @description Whether the input is read only
   */
  @Input() readonly: boolean = false;
  /**
   * @description The form control for the input
   */
  @Input() control!: FormControl;
  /**
   * @description The label for the input
   */
  @Input() label!: string;
  /**
   * @description The help text for the input
   */
  @Input() helpText?: string;
  /**
   *  @description The id for the input
   * */
  @Input() id!: string;
  /**
   * @description The type for the input
   */
  @Input() type: string = 'text';
  /**
   * @description The placeholder for the input
   */
  @Input() placeholder: string = ' ';
  /**
   * @description The pattern for the input
   */
  @Input() pattern: string = '';
  /**
   * @description The required for the input
   */
  @Input() required: boolean = false;
  /**
   * @description The min date for the input if it is a date input.
   * @note Provide the date in the format 'yyyy-MM-dd', e.g. '2021-01-01'.
   */
  @Input() min?: string;
  /**
   * @description The max date for the input if it is a date input
   * @note Provide the date in the format 'yyyy-MM-dd', e.g. '2021-01-01'.
   */
  @Input() max?: string;
  /**
   * @description The error messages for the input
   */
  @Input() errorMessages: { [key: string]: string } = {};

  /**
   * @description Reflects the inputmode attribute for the input
   */
  @Input() inputmode?: string;

  private defaultErrorMessages: { [key: string]: string } = {
    required: 'This field is required',
    email: 'Please enter a valid email address',
    pattern: 'Please enter a valid value',
    minlength: 'This field is too short',
    maxlength: 'This field is too long',
  };

  /**
   * @description The custom error template for the input
   * @slot - (default)
   * @note If this is provided, the error messages passed in via the `errorMessages` input will be ignored.
   */
  @ContentChild(TemplateRef) customErrorTemplate?: TemplateRef<{ $implicit: string | null }>;

  get errorMessage(): string | null {
    if (this.control.errors && (this.control.touched || this.control.dirty)) {
      const errorKey = Object.keys(this.control.errors)[0];
      return this.errorMessages[errorKey] || this.defaultErrorMessages[errorKey] || `This field is invalid`;
    }
    return null;
  }

  @Output() blurred = new EventEmitter<Event>();

  onBlur(event: Event): void {
    this.blurred.emit(event);
  }

  /**
   * Angular's 'updateOn' can only be 'blur' or 'submit' and we kind of need both.
   * For example, we type 01/01/2000 and hit submit. The value is still empty until you leave the field.
   * This method listens for enter and manually triggers blur.
   * @param event The key press event
   */
  onKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      const input = event.target as HTMLInputElement;
      input.blur();
      this.blurred.emit(event);

      const form = input.closest('form');
      if (form) {
        form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    }
  }
}
