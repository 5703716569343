import { Injectable, inject } from '@angular/core';
import { NgKLoggerService } from '@kin/ngk-logger';

import { OtherPolicyFeature } from 'app/common/services/legacy/policies/policies.model';
import { LegacyApiCoverage } from 'app/common/services/legacy/policies/policies.service';

import { isPercentageCoverage } from './other-features.utils';

@Injectable({
  providedIn: 'root',
})
export class OtherPolicyFeaturesMapperService {
  private logger = inject(NgKLoggerService);

  mapOtherPolicyFeatures(otherPolicyFeatures: LegacyApiCoverage[] | undefined): OtherPolicyFeature[] {
    if (!otherPolicyFeatures) {
      return [];
    }

    const mapped = otherPolicyFeatures.map((feature) => {
      // `limited_theft` is a one-off case where the label is driven by business logic in dot-com:
      // If the policy is HD3 and owner-occupied, the label is "Full Theft",
      // else, the label is "Limited Theft".
      if (feature.component.attribute_name === 'limited_theft') {
        return {
          id: feature.component.attribute_name,
          value: this.parseAndValidateValue(feature.value, feature),
          label: feature.component.label,
        };
      }

      return {
        id: feature.component.attribute_name,
        value: this.parseAndValidateValue(feature.value, feature),
      };
    });

    return mapped;
  }

  parseAndValidateValue(value: unknown, coverage?: LegacyApiCoverage): OtherPolicyFeature['value'] {
    if (value === null || value === undefined) {
      return undefined;
    }

    /**
     * For non-editable percentage values, return the formatted content
     */
    if (coverage?.editable === false && isPercentageCoverage(value) && typeof coverage.component?.content === 'string') {
      if (coverage?.component?.content === undefined) {
        this.logger.error({
          message: `No content found for ${coverage?.component?.attribute_name}`,
          context: 'OtherFeaturesMapperService::parseAndValidateValue',
          priority: 'P3',
        });
      }

      return coverage.component.content;
    }

    // Type guard for valid return types
    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      return value;
    }

    return undefined;
  }
}
